<template>
  <div>
    <el-dialog
      title="添加卡券"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
      :before-close="handleClose">
      <div class="tabs">
        <div class="tabs-item" @click="currTabs = 1" :class="currTabs === 1? 'active': ''">
          可用卡券({{cardList.available.length || 0}})
        </div>
      </div>

      <div style="height: 450px; overflow-y: auto; overflow-x: hidden;">
        <div class="radio" v-if="currTabs === 1">
          <el-checkbox-group v-model="radio"  @change="onchangeCoupon">
            <template v-for="(item,index) in cardList.available">
            <el-checkbox :label="item.coupon_log_id" style="margin-bottom: 10px;" class="couponBox_checkbox" :key="item.coupon_log_id" >
                <div class="couponBox">
                    <div class="couponBox_tag" v-if="item.type_seq === 10 || item.type_seq == 30">满减券</div>
                    <div class="couponBox_tag" v-if="item.type_seq === 20">礼品券</div>
                    <div class="couponBox_tag" v-if="item.type_seq === 40">折扣券</div>
                    <div class="couponContent">
                      <div class="couponMoney" >
                        <div class="type-coupon">
                          <span  v-if="item.type_seq == 10"><span class="symbol">￥</span>{{item.type_data}}</span>
                          <span  v-if="item.type_seq == 30" style="font-size:20px">{{item.type_data}}</span>
                          <span  v-if="item.type_seq === 20">{{item.type_data}}<span class="discount">折</span></span>
                          <span  v-if="item.type_seq === 40">礼品</span>
                        </div>
                          <div class="couponCondition" v-if="item.use_threshold">满{{ item.use_threshold }}使用
                        </div>
                        <div class="couponCondition" v-if="!item.use_threshold">无门槛</div>
                      </div>

                      <div class="backLine"></div>
                      <div class="couponAbout">
                        <div  class="couponTittle">{{item.coupon_name}} <el-tag size="mini">{{item.tag_name}}</el-tag></div>
                        <div class="couponTime">有效期: {{item.use_start_time}}-{{item.use_end_time}}</div>
                        <div class="Directions" @click.stop.prevent="clickshow(item.show,index,'available')">
                          <span>使用说明</span>
                          <img  v-if="item.show!=1"  src="../../../../assets/images/down.svg" />
                          <img  v-if="item.show==1"  src="../../../../assets/images/upward.svg" />
                        </div>
                      </div>

                    </div>
                     <div class="instructions" v-if="item.show==1" v-html="TransferString(item.specification)"></div>
                </div>
            </el-checkbox>
            </template>
          </el-checkbox-group>
          <!-- 不可用 -->
          <el-checkbox-group v-model="radio">
            <template v-for="(item,index) in cardList.unavailable">
              <el-checkbox :label="item.coupon_log_id" style="margin-bottom: 10px;" class="couponBox_checkbox" :key="item.coupon_log_id" disabled>
                <div class="couponBox">
                    <div class="couponBox_tag" v-if="item.type_seq === 10 || item.type_seq == 30">满减卷</div>
                    <div class="couponBox_tag" v-if="item.type_seq === 20">礼品卷</div>
                    <div class="couponBox_tag" v-if="item.type_seq === 40">折扣卷</div>
                    <div class="couponContent">
                      <div class="couponMoney" >
                        <div class="type-coupon">
                          <span  v-if="item.type_seq == 10"><span class="symbol">￥</span>{{item.type_data}}</span>
                          <span  v-if="item.type_seq == 30" style="font-size:20px">{{item.type_data}}</span>
                          <span  v-if="item.type_seq === 20">{{item.type_data}}<span class="discount">折</span></span>
                          <span  v-if="item.type_seq === 40">礼品</span>
                        </div>
                          <div class="couponCondition" v-if="item.use_threshold">满{{ item.use_threshold }}使用
                        </div>
                        <div class="couponCondition" v-if="!item.use_threshold">无门槛</div>
                      </div>

                      <div class="backLine"></div>
                      <div class="couponAbout">
                        <div  class="couponTittle">{{item.coupon_name}} <el-tag size="mini">{{item.tag_name}}</el-tag></div>
                        <div class="couponTime">有效期: {{item.use_start_time}}-{{item.use_end_time}}</div>
                        <div class="Directions" @click.stop="clickshow(item.show,index,'unavailable')">
                          <span>使用说明</span>
                          <img v-if="item.show!=1" src="../../../../assets/images/down.svg" />
                          <img  v-if="item.show==1"  src="../../../../assets/images/upward.svg" />
                        </div>
                      </div>

                    </div>
                     <div class="instructions" v-if="item.show==1" v-html="TransferString(item.specification)"></div>
                  </div>
              </el-checkbox>
            </template>
            <!-- <template v-for="item in cardList.unavailable">
              <el-checkbox :label="item.coupon_log_id" style="margin-bottom: 10px;" :key="item.coupon_log_id" disabled>
                <div class="box">
                  <div class="left">
                    <div class="price">
                      <template  v-if="item.type_seq === 10 ||item.type_seq == 20|| item.type_seq === 30">
                        <span>￥</span><span>{{item.type_data}}</span>
                      </template>
                      <template  v-if="item.type_seq == 20">
                        <span>折{{cardList.length}}</span>
                      </template>
                      <template>
                        <div  class="threshold_string">{{item.threshold_string}}</div>
                      </template>
                    </div>
                  </div>
                  <div class="right">
                    <div style="margin: 10px 0;">{{item.coupon_name}}<span style="padding-left:5px"><el-tag size="mini">{{item.tag_name}}</el-tag></span></div>
                    <div class="time">有效期：{{item.use_start_time}}-{{item.use_end_time}}</div>
                  </div>
                </div>
              </el-checkbox>
            </template> -->

          </el-checkbox-group>
          <div v-if="cardList.available.length<1&&cardList.unavailable.length<1" style="text-align: center;">暂无数据</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small ">取 消</el-button>
        <el-button type="primary" @click="handleTrue" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      currTabs: 1,
      coupon_log_id: [],
      radio:[],
      moment:moment,//挂载时间变量
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },

    cardList: {
      type: Object,
      default() {
        return {
          available: [],
          unavailable: [],
        };
      },
    },

    clearCard: {
      type: Boolean,
    },
    money: {
      type: [String, Number],
    },
    card: {
      type: Array,
    },
  },
  created() {

    if (this.clearCard) {
      this.coupon_log_ids = [];
    }

    if (this.card) {
      this.radio = this.card.map(item=>Number(item.coupon_log_id));
    }
  },
  methods: {
    //优惠卷说明显示展开方法
    clickshow(state,index,type){
      console.log(10)
      if(type=="unavailable"){
        if(state==1){
          this.cardList.unavailable[index].show=2;
        }else{
          this.cardList.unavailable[index].show=1;
        }
        this.$forceUpdate()
      }
      if(type=="available"){
        if(state==1){
          this.cardList.available[index].show=2;
        }else{
          this.cardList.available[index].show=1;
        }
        this.$forceUpdate()
      }
    },
    //换行替换
    TransferString(content){
      var string = content;
       try{
        string=string.replace(/\r\n/g,"</br>")
        string=string.replace(/\n/g,"</br>");
        }catch(e) {

        }
       return string;
    },
    onchangeCoupon(val){
      console.log(val,"val")
      this.$emit("onChooseCard",val);
    },

    handleTrue() {
      this.$emit('onHideCard');
      // if (!this.radio) {
      //   this.$message.warning('请选择优惠券');
      //   return;
      // }
      // if (!this.money) {
      //   this.$message.warning('订单金额为0不可使用优惠券');
      //   return;
      // }

      // if (this.currTabs === 1) {
      //   const arr = this.cardList.available.filter((item) => this.radio.includes(item.coupon_log_id));
      //   this.$emit('onChooseCard', arr);
      // }

      // if (this.currTabs === 2) {
      //   const arr = this.cardList.unavailable.filter((item) => this.radio.includes(item.coupon_log_id));
      //   this.$emit('onChooseCard', arr);
      // }
      // this.$emit('onHideCard');
    },


    handleClose() {
      this.$emit('onHideCard');
    },
  },
};
</script>
<style lang="less" >
  .couponBox_checkbox{
  .el-checkbox__input{
    vertical-align: top;
    color: #000;
    margin-top:50px;
  }
}
</style>
<style lang="less" scoped>
  .instructions{
  padding: 0 15px;
  font-size: 11px;
  text-align: left;
  color: #666666;
  line-height: 18px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #F6F6F6;
}

 .couponBox {
    position: relative;
    border-radius: 10px;
    border-bottom: 1px solid #eee;
    margin: 0 10px;
    margin-top: 10px;
    padding: 18.5px 0;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    .couponBox_tag{
      position: absolute;
      left: 0;
      top:0;
      background: #F9EAED;
      font-size: 11px;
      color: #E61612;
      padding: 2.5px 3.5px;
      border-radius: 0 5px 0 5px;
    }
    .couponBox_tag_overdue{
      background: #ccc;
      color: #fff;
    }
    .couponTip {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 16px;
    }

    .status {
      position: absolute;
      right: 0;
      top: 15px;
      bottom: 0;

      img {
        width: 57px;
        height: 65px;
      }
    }

    .couponContent {
      display: flex;
      align-items: center;
      width: 100%;

      .couponMoney {

        width: 35%;
        font-size: 28px;
        text-align: center;
        padding-top: 12px;
      }

      .backLine {
        width: 1px;
        height: 45px;
        margin: auto 18px;
        background: #ebebeb;
      }
    }

    .couponAbout {
      width: 300px;

      .couponTittle {
        font-family: PingFang SC;
        font-size: 14px;
        color:#333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .couponTime {
        margin-top: 6px;
        font-size: 11px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .couponbtn{
      width: 71.5px;
      height: 30px;
      border-radius: 15px 15px 15px 15px;
      background:rgba(222,49,35,1);
      margin-right: 10px;
      div{
        line-height: 30px;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
    }
  }
.couponCondition {
  margin-top: 6px;
  font-size: 12px;
  color: #DE3123;
}


.Directions{
  font-weight: 500;
  font-size: 12px;
  font-family: PingFang SC;
  color: #999999;
  margin-top: 7px;
  display: flex;
  align-items: center;
  img{
    width: 13px;
    padding-top: 2px;
    margin-left: 5px;
  }
}

.type-coupon {
  font-family: PingFang SC;
  font-size: 26px;
  color: #DE3123;
}
.symbol{
  font-size: 14px;
}


 /deep/ .el-button--primary {
  height: auto !important;
}
.threshold_string{
  text-align: center;
  color: #2a2a2a;
  font-size: 12px;
  padding-top: 10px;
}
.radio {
  margin-top: 10px;
  // padding-left: 10px;
  /deep/ .el-radio {
    display: flex;
    align-items: center;
  }
  /deep/ .el-radio__label  {
    display: block;
  }
  .box {
    border: 1px solid #eee;
    width: 500px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    .left {
      width: 35%;
      border: 1px dashed #eee;
      padding: 0 10px;
      box-sizing: border-box;
      .price {
        color: #409EFF;
        font-size: 25px;
        text-align: center;
        margin: 30px 0 5px 0;
        span {
          font-size: 16px;
        }
      }
      .label {
        color: #ccc;
        font-size: 12px;
        text-align: center;
      }
    }
    .right {
      width: 65%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      .time {
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}

.tabs {
  border-bottom: 1px solid #eee;
  display: flex;
  padding-bottom: 15px;
  &-item {
    width: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .active {
    color: #409EFF;
    &::after {
      content: '';
      width: 20px;
      height: 4px;
      background: #409EFF;
      bottom: -15px;
      left: 45%;
      position: absolute;
      border-radius: 99px;
    }
  }
}
</style>
