<template>
  <div>
    <el-dialog
      title="添加旧料"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
      :before-close="handleClose"
    >
      <el-form :model="form" label-width="150px" :inline="true">
        <!-- <el-form-item label="货号/条码号">
          <el-input
            placeholder="在本店销售回收的货品"
            v-model="form.goods_number_search"
            clearable
            @input="handleInput"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="货品类型" required>
          <el-select v-model="form.goods_number_type">
            <el-option label="一码一货" :value="10"></el-option>
            <el-option label="一码多货" :value="20"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="仓库" required>
          <el-select v-model="form.warehouse_id">
            <template v-for="item in storeList">
              <el-option
                :label="item.warehouse_name"
                :value="item.warehouse_id"
                :key="item.warehouse_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="供应商">
          <el-select v-model="form.supplier_id">
            <template v-for="item in supplierList">
              <el-option
                :label="item.supplier_name"
                :value="item.supplier_id"
                :key="item.supplier_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item> -->
        <el-form-item label="品类" required>
          <el-select
            v-model="form.goods_type_id"
            @change="handleChange"
          >
            <template v-for="item in select">
              <el-option
                :label="item.goods_type_name"
                :value="item.goods_type_id"
                :key="item.goods_type_id"
                @click.native="handleOpt(item.goods_type_name)"
              >
                <span :style="{ marginLeft: item.span * 8 + 'px' }">{{
                  item.goods_type_name
                }}</span>
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <template v-for="item in formItemList">
          <el-form-item
            :label="item.attr_name"
            :key="item.attr_fields"
            :required="item.is_mandatory === 20"
          >
            <template v-if="item.type === 10">
              <template v-if="item.attr_fields === 'recycle_price'">
                <!-- <el-input v-model="form[item.attr_fields]" clearable  @input="(val)=>{ form[item.attr_fields] = val.replace(/[^\d]/g, '')}"></el-input> -->
                <el-input v-model="form[item.attr_fields]" clearable  type="number" @input="handleInputNumber($event, item)"></el-input>
              </template>
              <template v-else>
                <el-input v-model="form[item.attr_fields]" clearable @input="handleInputNumber($event, item)"></el-input>
              </template>
            </template>
            <template v-if="item.type === 20">
              <el-select v-model="form[item.attr_fields]" clearable @change="handleSelect($event, item)">
                <el-option
                  v-for="opt in item.val_list"
                  :key="opt.attr_value_id"
                  :label="opt.attr_value"
                  :value="opt.attr_value"
                ></el-option>
              </el-select>
            </template>
            <template v-if="item.type === 30">
              <template>
                <el-input v-model="form[item.attr_fields]" clearable type="number" @input="handleInputNumber($event, item)"></el-input>
              </template>
            </template>
            <template v-if="item.type === 40">
              <template>
                <el-input v-model="form[item.attr_fields]" disabled placeholder="自动计算"></el-input>
              </template>
            </template>
          </el-form-item>
        </template>
        <el-form-item label="关联货品" v-if="formItemList.length" :required="goods.length ? true : false">
          <el-select v-model="form.exchange_goods_number" @change="handleChangeLink">
            <template v-for="item in goods">
              <el-option :label="item.goods_name + '（' + item.goods_number + ')'" :key="item.goods_number" :value="item.goods_number"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" v-if="formItemList.length">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'GOODS_RAW_PIC',
            }"
            :headers="{
              authorization: token,
            }"
            :show-file-list="false"
            :on-success="handleLicenseUrlSuccess"
          >
            <img
              v-if="form.goods_pic"
              :src="form.goods_pic"
              style="object-fit: cover"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div v-if="recycle_price" style="color: #67C23A; margin-left: 80px;">*预估回收金额￥{{recycle_price}}</div>
      <span v-if="expression_str" style="color: #F56C6C; margin-left: 80px;">*回收金额 = {{expression_str}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRecycleGoodsInfoReq,
  getStoreListReq,
  getSupplierListReq,
  getSaleGoodsInfoReq,
  getRecoverGoldPriceReq,
} from "@/api/order/offlineOrder/add";
import { getListReq2 as getTypeListReq } from "@/api/goods/goodsConfig/classify";
import { getRecFormulaReq, getRecoverAmountReq } from '@/api/system/formula/list';
import Cookies from "js-cookie";
import storage from "good-storage";

export default {
  data() {
    return {
      recycle_price: '',
      expression_str: '',
      token: '',
      timer: null, // 定时器
      supplierList: [], // 供应商列表
      storeList: [], // 仓库列表
      formItemList: [], // 表单动态项列表
      select: [], // 品类的下拉项
      form: {
        goods_number_search: "",
        goods_number_type: 10,
      },
    };
  },
  props: {
    goods: {
      type: Array,
      default: () => ([]),
    },
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    oldRow: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    formComputed: {
      handler(newVal, oldVal) {
        if ((newVal.goods_type_id !== oldVal.goods_type_id) && oldVal.goods_type_id) {
          this.form = {
            goods_type_id: newVal.goods_type_id,
            index: newVal.index,
            goods_number: newVal.goods_number,
          };
        }
      },
    },
  },
  computed: {
    formComputed() {
      return JSON.parse(JSON.stringify(this.form));
    },
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getStoreList();
    this.getTypeList();
    this.getSupplierList();
    if (this.oldRow.goods_type_id) {
      this.handleChange(this.oldRow.goods_type_id);
      this.form = this.oldRow;
      console.log(this.form);
    }
  },
  methods: {
    handleChangeLink(e) {
      let exchange_goods_name = '';
      this.goods.forEach((item) => {
        if (item.goods_number === e) {
          exchange_goods_name = `${item.goods_name}(${item.goods_number})`
        }
      })
      this.form = {
        ...this.form,
        exchange_goods_name,
      }
      console.log(this.form);
    },
    handleSelect(val, item) {
      if (item.system_attr_id === 100) {
        getRecoverGoldPriceReq({
          material_name: val,
        })
          .then((res) => {
            if (res.code === 1) {
              this.formItemList.forEach((item) => {
                if (item.system_attr_id === 1449) {
                  const form = { ...this.form };
                  form[item.attr_fields] = res.data.gold_price;
                  this.form = form;
                }
              });
              this.getRecoverAmount();
            }
          });
      }
    },
    getRecoverAmount() {
      getRecoverAmountReq({
        goods_type_id: this.form.goods_type_id,
        form_data: { ...this.form },
      })
        .then((res) => {
          if (res.code === 1) {
            const form = { ...this.form };
            form.recycle_price = Math.floor(res.data);
            this.form = form;
            this.recycle_price = res.data;
          }
        });
    },
    handleLicenseUrlSuccess(response) {
      if (response.code === 1) {
        const form = { ...this.form };
        form.goods_pic = response.data;
        this.form = form;
      } else {
        this.$message.error(response.msg);
      }
    },
    handleInputNumber(e, item) {
      if (item.type === 30) {
        this.getRecoverAmount();
      }
      // 自动计算
      const row = {
        ...this.form,
      };
      this.formItemList.forEach((item) => {
        if (item.type === 40) {
            row[item.attr_fields] = eval(item.caclu_font);
            if (!row[item.attr_fields]) {
              row[item.attr_fields] = '';
            }
        }
      })
      this.form = row;
    },
    // 输入货号
    handleInput(e) {
      if (e) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setTimeout(() => {
          getSaleGoodsInfoReq(e).then((res) => {
            if (res.code === 1) {
              this.formItemList = res.data.attrList;
              this.form = {
                goods_number_search: this.form.goods_number_search,
                ...res.data.goodsInfo,
                goods_number_type: res.data.goods_number_type,
                goods_id: res.data.goods_id,
                goods_type_id: res.data.goods_type_id,
                order_goods_id: res.data.order_goods_id,
                supplier_id: res.data.supplier_id,
                warehouse_id: res.data.warehouse_id,
              };
              console.log(res.data);
            }
          });
        }, 500);
      }
    },
    // 获取供应商列表
    getSupplierList() {
      getSupplierListReq().then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data;
        }
      });
    },
    // 获取仓库列表
    getStoreList() {
      getStoreListReq().then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    // 点击确定
    handleTrue() {
      // if (!this.form.warehouse_id) {
      //   this.$message.error("仓库为必填项");
      //   return;
      // }
      if (!this.form.goods_type_id) {
        this.$message.error("品类为必填项");
        return;
      }
      // 验证必填
      for (let i = 0; i < this.formItemList.length; i++) {
        const item = this.formItemList[i];
        if (item.is_mandatory === 20) {
          if (!this.form[item.attr_fields]) {
            this.$message.error(`${item.attr_name}为必填项`);
            return;
          }
        }
      }
      if (!this.form.exchange_goods_number && this.goods.length) {
        this.$message.error(`关联货品为必填项`);
        return;
      }
      // 根据后端要求，货号为空也需要传递字段
      if (!this.form.goods_number) {
        this.form.goods_number = "";
      }
      if (!this.form.pcs) {
        this.form.pcs = 1;
      }
      // 组件后端需要信息
      this.formItemList.forEach((item) => {
        if (item.system_attr_id === 100) {
          this.form.good_material_name = this.form[item.attr_fields];
        }
        if (item.system_attr_id === 104) {
          this.form.gold_weight = this.form[item.attr_fields];
        }
      });
      // 将form信息传递给父组件
      this.$emit("onOld", this.form);
      this.handleClose();
      console.log(this.form);
    },
    handleOpt(goods_type_name) {
      if (goods_type_name.indexOf("├─") > -1) {
        this.form.goods_type_name = goods_type_name.slice(2);
        return;
      }
      this.form.goods_type_name = goods_type_name;
    },
    // 品类选项改变
    handleChange(val) {
      getRecycleGoodsInfoReq(
        val,
        this.form.goods_number_type,
        this.form.warehouse_id,
        this.form.supplier_id
      ).then((res) => {
        if (res.code === 1) {
          // res.data.forEach((item) => {
          //   item.attr_fields = item.attr_fields.toString();
          // });
          this.formItemList = res.data;
          // this.$emit('onOldRowList', this.formItemList);
        }
      });
      getRecFormulaReq({
        goods_type_id: val,
      })
        .then((res) => {
          if (res.code === 1) {
            this.expression_str = res.data.expression_str;
          }
        })
    },
    // 处理递归
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    // 获取品类
    getTypeList() {
      getTypeListReq({
        goods_type: 20,
      }).then((res) => {
        if (res.code === 1) {
          // 如果有数据，当前不是新增并且获取对应详情
          if (res.data.length) {
            this.select = this.handleArr(res.data);
          }
        }
      });
    },
    handleClose() {
      this.$emit("onHideOld");
      setTimeout(() => {
        this.form = {};
        this.formItemList = [];
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  width: 217px;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
