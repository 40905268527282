<template>
  <div>
    <el-dialog
      title="挂起订单列表"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="table" style="border-top: 1px solid #ddd;">
        <el-table
            :data="tableData"
            style="width: 100%"
            v-loading="loading"
            height="500"
          >
            <template v-for="item in rowList">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
                align="center">
                <template slot-scope="scope">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align='center'
              field_alias='operation'
              label='操作'
              width="180"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="getOne(scope.row)">提取</el-button>
                <el-popconfirm
                    title="确定要取消改挂单吗？"
                    @confirm="handleTrue(scope.row)"
                  >
                  <el-button type="text" slot="reference" style="color: #F56C6C; margin-left: 10px;">取消挂单</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListReq, cancelReq, getOneReq } from '@/api/order/offlineOrder/hand';

export default {
  data() {
    return {
      loading: false,
      form: {},
      tableData: [],
      rowList: [
        { field_text: '挂单号', field_alias: 'put_order_sn', t_width: 150 },
        { field_text: '订单金额(元)', field_alias: 'total_money' },
        { field_text: '货品数量', field_alias: 'total_num' },
        { field_text: '会员姓名', field_alias: 'client_user_name' },
        { field_text: '手机号', field_alias: 'client_user_phone', t_width: 120 },
        { field_text: '销售', field_alias: 'store_user_name' },
        { field_text: '挂单人', field_alias: 'create_user_name' },
        { field_text: '挂单时间', field_alias: 'create_time', t_width: 160 },
      ],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getOne(row) {
      getOneReq(row.put_order_sn)
        .then((res) => {
          if (res.code === 1) {
            this.$emit('onCloseHand');
            this.$emit('onDetail', res.data);
          }
        });
    },
    getList() {
      this.loading = true;
      getListReq()
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit('onCloseHand');
    },
    handleTrue(row) {
      cancelReq(row.put_order_sn)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('取消挂单成功');
            this.getList();
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
