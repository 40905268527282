<template>
  <div style="background: #f6f7fb" v-loading="mountLoading">
    <div class="card">
      <el-page-header @back="goBack" :content="recycle_order_id ? '回收单反确认' : '新增订单'"></el-page-header>
    </div>
    <div style="
          background: white;
          padding: 10px;
          margin: 10px 0;
          width: 77%;
          box-sizing: border-box;
        " v-if="reverse">
      <el-button type="primary" @click="handleOrigin">恢复原订单数据</el-button>
    </div>
    <div class="top-wrapper">
      <div class="number">
        <div class="header" style="display: flex; justify-content: space-between">
          <span>会员信息</span>
          <el-button type="primary" size="mini" plain :disabled="reverse && memberInfo.client_user_id ? true : false" @click="numberVisible = true">
            {{ memberInfo.client_user_id ? "重新添加" : "添加会员" }}
          </el-button>
        </div>
        <div class="number-content">
          <div class="number-avatar">
            <img class="img" :src="memberInfo.avatar" v-if="memberInfo.avatar" />
            <img class="img" src="../../../../assets/images/member/moren.png" v-else />
            <!-- <img class="label" /> -->
          </div>
          <div class="number-list">
            <div class="number-item">
              <span>会员名称</span>
              <span>{{ memberInfo.realname || "--" }}</span>
            </div>
            <div class="number-item">
              <span>可用积分</span>
              <span>{{ memberInfo.integral || 0 }}</span>
            </div>
            <div class="number-item">
              <span>会员等级</span>
              <span>{{ memberInfo.client_level_text || "--" }}</span>
            </div>
            <div class="number-item">
              <span>会员手机号</span>
              <span>{{ memberInfo.username || "--" }}</span>
            </div>
            <div class="number-item">
              <span>累计消费积分</span>
              <span>{{ memberInfo.consume_integral || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="header">
          <span>销售信息</span>
          <div style="margin-left: auto;">
            <el-button size="mini" @click="ratioVisible = true;mainRatioCopy = mainRatio;checkedCopy = checked;">主销比例设置
            </el-button>
            <el-button size="mini" type="primary" style="height: 28px;" @click="handleAddSaler" :disabled="employeeList.length === ratioList.length || !setPercent">添加销售
            </el-button>
          </div>
        </div>
        <el-form :model="form" :inline="true" style="margin-top: 50px" class="saler">
          <el-form-item label="主销售" required>
            <div style="display: flex;">
              <el-select placeholder="请选择主销售" v-model="ratioList[0].store_user_id" clearable style="width: 150px">
                <template v-for="item in employeeList">
                  <el-option v-if="item.store_user_id" :key="item.store_user_id" :label="item.realname" :value="item.store_user_id"></el-option>
                </template>
              </el-select>
              <div class="ratio" v-if="ratioList.length > 1">
                <input style="outline: none;" :value="ratioList[0].ratio" @input="handleInputRatio($event)" type="number" />%
              </div>
            </div>
          </el-form-item>
          <template v-for="(Item, index) in ratioList">
            <el-form-item label="辅销售" label-width="64px" :key="Item.id" v-if="index !== 0" :style="{ marginLeft: index === 1 ? '25px' : '' }">
              <div style="display: flex;">
                <el-select placeholder="请选择辅销售" v-model="Item.store_user_id" clearable style="width: 150px">
                  <template v-for="item in employeeList">
                    <el-option v-if="item.store_user_id" :key="item.store_user_id" :label="item.realname" :value="item.store_user_id"></el-option>
                  </template>
                </el-select>
                <div class="ratio">
                  <input style="outline: none;" v-model="Item.ratio" @input="handleInputRatio($event, index)" type="number" />%
                </div>
                <i class="el-icon-remove" style="color: #409eff; font-size: 20px; margin: 10px 0 0 5px; cursor: pointer;" @click="handleDeleSaler(index)"></i>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
    <div class="wrapper">
      <div class="left">
        <div class="info" style="margin-top: 0; padding-top: 20px">
          <div class="header" style="margin-bottom: 0">
            货品信息
            <i class="el-icon-warning" style="color: #e6a23c; margin: 0 10px"></i>注意：当货品添加仅有 <span style="color: #f56c6c">旧料</span>时，订单类型为
            <span style="color: #f56c6c">回收单</span>。
          </div>
          <div style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
              ">
            <div class="top">
              <div class="no-bottom">
                <el-form :model="form" :inline="true" @submit.native.prevent>
                  <el-form-item label="货品添加">
                    <el-input placeholder="请扫描条码或手动填写入库，按Enter键结束" v-model="goods_number" @keyup.enter.native="onSubmit($event, goods_number)" clearable></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="btns">
                <el-button type="primary" @click="handleGoodVisibel('newArrival')">筛选货品</el-button>
                <el-button type="primary" plain @click="
    oldVisible = true;
  oldRow = {};
                  ">添加旧料
                </el-button>
                <el-button type="success" plain @click="handleGoodVisibel('gift')">添加赠品</el-button>
                <el-button type="danger" plain style="margin-left: 10px" @click="handleAllClear">
                  清空货品
                </el-button>
              </div>
            </div>
            <el-button v-show="!reverse" v-if="newArrival.length || gift.length || raw.length" type="warning" plain @click="handleHand">挂单
            </el-button>

            <el-button v-else type="warning" plain @click="handVisible = true" v-show="!reverse">
              提单
            </el-button>
          </div>

          <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <div style="font-weight: bold; font-size: 16px">货品（共{{ total_new }}件）</div>
              </template>
              <el-button type="primary" v-if="canSaleByCost" @click="handleTotalChange" :disabled="canUseCostPrice ? false : true">批量成本价销售
              </el-button>
              <div>
                <!-- <el-radio-group v-model="radio">
                    <el-radio :label="1">会员折扣</el-radio>
                    <el-radio :label="2">手动折扣</el-radio>
                  </el-radio-group>-->
                <div class="table" :class="newArrival.length ? 'no-border' : ''" v-if="!newRowsLoading">
                  <el-table :data="newArrival" style="width: 100%; margin: 20px 0" :row-class-name="handleIndex" border max-height="309px">
                    <template v-for="item in newRowList">
                      <el-table-column v-if="item.field_alias!='actual_sale_price'" :key="item.field_alias" :prop="item.field_alias" :label="item.field_text" :width="item.t_width + 'px'" align="center">
                        <template slot-scope="scope">
                          <template v-if="item.field_alias === 'goods_number_type'">
                            {{ scope.row.goods_number_type == 10 ? "一码一货" : "一码多货" }}
                          </template>
                          <template v-else-if="item.field_alias === 'sales_type'">
                            <span v-if="scope.row.sales_type == 10">按克</span>
                            <span v-else-if="scope.row.sales_type == 20">按件</span>
                            <span v-else>--</span>
                          </template>
                          <!-- 销售工费 -->
                          <!-- <template v-else-if="item.field_alias === '184'">
                              <span v-if="scope.row.sales_type == 20">{{
                                scope.row["184"]
                              }}</span>
                              <el-input
                                v-else-if="scope.row.sales_type == 10"
                                v-model="scope.row['184']"
                                @input="handleGongFei($event, scope.row.index)"
                              ></el-input>
                              <span v-else>--</span>
                            </template>-->
                          <!-- 今日金价 -->
                          <template v-else-if="item.field_alias === 'gold_price'">
                            <span v-if="scope.row.sales_type == 20">
                              {{ scope.row.gold_price }}
                            </span>
                            <el-input @focus="handleSelectAll($event)" v-else-if="scope.row.sales_type == 10" v-model="scope.row.gold_price" @input="handleGoldPrice($event, scope.row.index)">
                            </el-input>
                            <span v-else>--</span>
                          </template>
                          <template v-else-if="item.field_alias === 'num'">
                            <span v-if="scope.row.goods_number_type == 10">
                              {{ scope.row.num }}
                            </span>
                            <el-input v-else v-model="scope.row.num" @input="handleNum($event, scope.row.index)">
                            </el-input>
                          </template>
                          <template v-else-if="item.field_alias === 'goods_name'">
                            <div style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                ">
                              {{ scope.row.goods_name || "--" }}
                              <template v-for="item in scope.row.goods_tag_pic">
                                <img :src="item" :key="item" style="margin-left: 5px; height: 15px" />
                              </template>
                            </div>
                          </template>
                          <template v-else-if="item.field_alias === 'sale_warehouse'">
                            <template v-if="scope.row.stockList">
                              <template v-if="scope.row.stockList.length === 1">{{
                                    scope.row.stockList[0].warehouse_name
                                  }}
                              </template>
                              <template v-else-if="scope.row.stockList.length > 1">
                                <el-select v-model="scope.row.sale_warehouse">
                                  <el-option v-for="item in scope.row.stockList" :key="item.warehouse_id" :value="item.warehouse_id" :label="item.warehouse_name"></el-option>
                                </el-select>
                              </template>
                              <template v-else>--</template>
                            </template>
                          </template>
                          <template v-else>
                            {{
                                scope.row[item.field_alias] || scope.row[item.field_alias] === 0
                                  ? scope.row[item.field_alias]
                                  : "--"
                              }}
                          </template>
                        </template>
                      </el-table-column>
                    </template>
                    <el-table-column align="center" field_alias="operation" label="操作" width="100" fixed="right">
                      <template slot-scope="scope">
                        <el-button type="text" style="color: #f56c6c" @click="
                            handleDele('newArrival', scope.row.goods_id, scope.row.goods_number)
                          ">移除
                        </el-button>
                        <el-button type="text" @click="handleChangePrice(scope.row)">改价</el-button>
                        <!-- <div class="options">
                            <el-select
                              v-model="scope.row.select"
                              placeholder="请选择"
                              @change="handleChange($event, scope.row)"
                            >
                              <el-option label="移除" :value="1"></el-option>
                              <el-option label="改价" :value="2"></el-option>
                            </el-select>
                          </div>-->
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-collapse-item>

            <el-collapse-item name="2">
              <template slot="title">
                <div style="font-weight: bold; font-size: 16px">旧料（共{{ total_raw }}件）</div>
              </template>
              <div class="table" :class="raw.length ? 'no-border' : ''">
                <el-table :data="raw" style="width: 100%; margin: 20px 0" border :row-class-name="handleIndex" max-height="309px">
                  <template v-for="item in oldRowList">
                    <el-table-column :key="item.field_alias" :prop="item.field_alias" :label="item.field_text" align="center">
                      <template slot-scope="scope">
                        <template v-if="item.field_alias === 'goods_pic'">
                          <img :src="scope.row[item.field_alias]" v-if="scope.row[item.field_alias]" class="img" />
                        </template>
                        <template v-else>
                          {{ scope.row[item.field_alias] ? scope.row[item.field_alias] : "--" }}
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column align="center" field_alias="operation" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                      <el-button type="text" style="color: #f56c6c" @click="handleMove('raw', scope.row)">移除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <div style="font-weight: bold; font-size: 16px">赠品（共{{ total_gift }}件）</div>
              </template>
              <div class="table" :class="gift.length ? 'no-border' : ''">
                <el-table :data="gift" style="width: 100%; margin: 20px 0" border max-height="309px">
                  <template v-for="item in giftRowList">
                    <el-table-column :key="item.field_alias" :prop="item.field_alias" :label="item.field_text" align="center">
                      <template slot-scope="scope">
                        <template v-if="
                            item.field_alias !== 'num' &&
                            item.field_alias !== 'money' &&
                            item.field_alias !== 'goods_name' &&
                            item.field_alias !== 'sale_warehouse'
                          ">
                          {{ scope.row[item.field_alias] ? scope.row[item.field_alias] : "--" }}
                        </template>
                        <template v-if="item.field_alias === 'num'">
                          <span v-if="scope.row.goods_number_type == 10">
                            {{ scope.row.num || scope.row.pcs }}
                          </span>
                          <template v-else>
                            <el-input v-if="scope.row.num" v-model="scope.row.num"></el-input>
                            <!-- <el-input v-if="scope.row.pcs" v-model="scope.row.pcs"></el-input> -->
                          </template>
                        </template>
                        <template v-if="item.field_alias === 'money'">0.00</template>
                        <template v-if="item.field_alias === 'goods_name'">
                          <div style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              ">
                            {{ scope.row.goods_name || "--" }}
                            <template v-for="item in scope.row.goods_tag_pic">
                              <img :src="item" :key="item" style="margin-left: 5px; height: 15px" />
                            </template>
                          </div>
                        </template>
                        <template v-if="item.field_alias === 'sale_warehouse'">
                          <template v-if="scope.row.stockList">
                            <template v-if="scope.row.stockList.length === 1">{{
                                  scope.row.stockList[0].warehouse_name
                                }}
                            </template>
                            <template v-else-if="scope.row.stockList.length > 1">
                              <el-select v-model="scope.row.sale_warehouse">
                                <el-option v-for="item in scope.row.stockList" :key="item.warehouse_id" :value="item.warehouse_id" :label="item.warehouse_name"></el-option>
                              </el-select>
                            </template>
                            <template v-else>--</template>
                          </template>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column align="center" field_alias="operation" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" style="color: #f56c6c" @click="handleMove('gift', scope.row)">移除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
          <!-- <div class="total">
              <div class="total-left">合计</div>
              <div class="total-right">
                <div class="total-right-item">货品总数：<span>{{'0'}}</span></div>
                <div class="total-right-item">金额合计：<span>{{'0'}}</span></div>
              </div>
            </div>-->
        </div>
        <div class="info" style="padding-top: 20px; flex: 1">
          <div class="header">使用优惠</div>
          <div style="display: flex">
            <el-form style="flex: 1" :model="youhuiForm" label-width="120px">
              <el-form-item label="抹零金额" style="margin-bottom: 20px">
                <el-input v-model="total.clearMoney" @input="handleClearMoney" style="width: 70%" :disabled="!newArrival.length"></el-input>
              </el-form-item>
              <el-form-item label="整单优惠" style="margin-bottom: 20px">
                <el-input v-model="total.youhuiMoney" @input="handleyouhuiMoney" style="width: 70%" :disabled="!total.orderMoney"></el-input>
              </el-form-item>

              <el-form-item label="优惠卡券" style="margin-bottom: 20px">
                <el-button type="success" plain @click="handleCard">添加卡券</el-button>
                <span style="margin-left: 10px; color: #333">
                  共{{ cardList.total || 0 }}张优惠券，{{ cardList.available.length || 0 }}张可用
                </span>

                <div v-if="card.length>0" style="margin: 0 10px">
                  当前使用：
                  <template v-for="item in card">
                    <span :key="item.coupon_sn" style="color: #409eff">
                      {{ item.coupon_sn }}
                    </span>
                  </template>

                </div>
                <el-button v-if="card.client_card_voucher_id" type="danger" plain @click="handleClearCard">删除优惠券
                </el-button>
              </el-form-item>
              <!--              <el-form-item label="预存抵扣" style="margin-bottom: 20px">-->
              <!--                <el-button type="primary" plain @click="handlePre">添加预存</el-button>-->
              <!--                <span style="margin-left: 10px; color: #333">-->
              <!--                  共{{ preList.total || 0 }}张预存券，{{ preList.available.length || 0 }}张可用-->
              <!--                </span>-->
              <!--                <span v-if="pre.store_prestore_user_id" style="margin: 0 10px">-->
              <!--                  当前使用：-->
              <!--                  <span style="color: #409eff">-->
              <!--                    {{ pre.title }}-->
              <!--                  </span>-->
              <!--                </span>-->
              <!--                <el-button v-if="pre.store_prestore_user_id" type="danger" plain-->
              <!--                           @click="handleClearPre">删除预存-->
              <!--                </el-button>-->
              <!--              </el-form-item>-->
              <el-form-item label="积分抵扣金额(元)" style="margin-bottom: 20px">
                <!-- :placeholder="'请输入积分抵扣金额, 可用积分' + (memberInfo.integral || 0) + ',最多抵扣' + (this.amount.order_lower_amount || 0) + '元'" -->
                <el-input placeholder="请输入金额(元)" type="number" v-model="orderInfo.use_money" style="width: 70%" :min="0" :max="amount.order_lower_amount" @input="handleInput" :disabled="!memberInfo.client_user_id || !total.orderMoney"></el-input>
                <span v-if="loading2" style="margin-left: 5px">计算积分中...</span>
                <span v-if="!loading2 && (integralInfo || integralInfo === 0)" style="margin-left: 5px; color: #e7541e">
                  使用了{{ orderInfo.use_inegral }}积分
                  <span v-if="scaleOut">，积分抵扣金额超过订单金额，请重新输入。</span>
                </span>
              </el-form-item>
              <div style="line-height: 30px; color: #f56c6c">
                <div>
                  *当前拥有积分：{{ memberInfo.integral || 0 }}（最多可抵扣{{
                      this.amount.member_amount || 0
                    }}元）
                </div>
                <div>
                  *本订单可用积分：{{ this.amount.order_integral || 0 }}（即本订单最多可抵扣{{
                      this.amount.order_lower_amount || 0
                    }}元）
                </div>
              </div>
            </el-form>
            <div style="flex: 0.8; margin-left: 60px;">
              <div>
                <el-checkbox v-model="useSaving" label="使用储值金" border @change="handleChangeSaving"></el-checkbox>
                <span style="margin-left: 10px" v-if="useSaving">
                  已抵扣
                  <span style="margin: 0 10px; color: #e7541e">{{
                        storageMoneyInfo.use_money
                      }}元</span>
                </span>
                <i class="el-icon-edit" style="font-size: 20px; cursor: pointer" @click="savingVisible = true" v-if="useSaving"></i>
              </div>
              <div style="font-size: 14px; margin-top: 20px" v-if="useSaving">
                <span>
                  当前储值金余额
                  <span style="color: #2791ff">
                    {{ storageMoneyInfo.client_storage_money_org || 0 }}元
                  </span>
                </span>
                <span style="margin-left: 20px">
                  本订单可抵扣
                  <span style="color: #2791ff">{{ storageMoneyInfo.max_storage_money }}元</span>
                </span>
              </div>
              <div style="font-size: 14px; margin-top: 18px; color: #909399" v-if="useSaving">
                *储值金根据设定的品类比例自动分配，可编辑品类抵扣金额。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="reverse">
          <div class="header">原订单结算明细</div>
          <div class="list">
            <div class="item">
              <div style="color: black">实收金额</div>
              <div style="
                    font-weight: bold;
                    font-size: 16px;
                    color: rgb(245, 108, 108);
                  ">
                ￥{{ mountOrderInfo.orderInfo.pay_amount || "0.00" }}
              </div>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="header">
            <span v-if="reverse">修改订单</span>结算明细
          </div>
          <div class="list">
            <div style="
                  font-size: 14px;
                  font-weight: bold;
                  border-bottom: 1px dashed #ccc;
                  line-height: 40px;
                ">
              货品
            </div>
            <div class="item">
              <div>订单金额</div>
              <div style="color: #f56c6c">￥{{ total.orderMoney || "0.00" }}</div>
            </div>
            <div style="
                  font-size: 14px;
                  font-weight: bold;
                  border-bottom: 1px dashed #ccc;
                  line-height: 40px;
                ">
              旧料
            </div>
            <div class="item">
              <div>旧料金额</div>
              <div>￥-{{ total.oldMoney || "0.00" }}</div>
            </div>
            <div style="
                  font-size: 14px;
                  font-weight: bold;
                  border-bottom: 1px dashed #ccc;
                  line-height: 40px;
                ">
              优惠折扣
            </div>
            <div class="item">
              <div>预存金额</div>
              <div>￥-{{ total.preMoney || "0.00" }}</div>
            </div>

            <div class="item">
              <div>优惠券折扣金额</div>
              <div>￥-{{ discount['40'] || "0.00" }}</div>
            </div>

            <div class="item">
              <div>积分抵扣金额</div>
              <div>￥-{{ integralInfo || "0.00" }}</div>
            </div>
            <div class="item">
              <div>抹零金额</div>
              <div>￥-{{ total.clearMoney || "0.00" }}</div>
            </div>
            <div class="item">
              <div>折扣金额</div>
              <div v-if="Number(total.memberMoney) < 0">
                ￥+{{ Math.abs(total.memberMoney) || "0.00" }}
              </div>
              <div v-else>￥-{{ total.memberMoney || "0.00" }}</div>
            </div>
            <div class="item">
              <div>整单优惠</div>
              <div>￥-{{ total.youhuiMoney || "0.00" }}</div>
            </div>
            <div style="
                  font-size: 14px;
                  font-weight: bold;
                  border-bottom: 1px dashed #ccc;
                  line-height: 40px;
                ">
              储值金
            </div>
            <div class="item">
              <div>储值金抵扣</div>
              <div v-if="useSaving">￥-{{ storageMoneyInfo.use_money || "0.00" }}</div>
              <div v-else>￥-{{ "0.00" }}</div>
            </div>
            <el-divider></el-divider>
            <div class="item">
              <div style="color: black">实收金额</div>
              <div style="
                    font-weight: bold;
                    font-size: 16px;
                    color: rgb(245, 108, 108);
                  ">
                ￥{{ total.actualMoney || "0.00" }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-divider></el-divider>
          <el-button type="primary" block @click="hanldeSettlement" class="btn">结算</el-button>
          <div style="display: flex">
            <!-- <el-button type="warning" plain @click="handleGua" class="btn" v-show="!reverse">挂单</el-button> -->
            <el-button type="danger" plain class="btn" @click="goBack">关闭</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="结算" :visible.sync="settlementVisible" width="30%" :show-close="false" :before-close="handleClose">
      <el-form :model="settlementForm" class="money">
        <div style="max-height: 500px; overflow-y: scroll;">
          <template v-for="item in payList">
            <el-form-item :label="item.name" :key="item.pay_type">
              <el-input :placeholder="'请填写' + item.name + '金额'" v-model="payInfo[item.pay_type]" type="number">
              </el-input>
            </el-form-item>
          </template>
        </div>
        <!-- <el-form-item label="微信">
            <el-input
              placeholder="请填写微信收款金额"
              v-model="payInfo['20']"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付宝">
            <el-input
              placeholder="请填写支付宝收款金额"
              v-model="payInfo['30']"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="现金">
            <el-input
              placeholder="请填写现金收款金额"
              v-model="payInfo['10']"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="银联">
            <el-input
              placeholder="请填写银联收款金额"
              v-model="payInfo['40']"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他">
            <el-input
              placeholder="请填写其他收款金额"
              v-model="payInfo['50']"
              type="number"
            ></el-input>
          </el-form-item>-->
        <el-form-item label="抹零金额" v-show="false">
          <el-input placeholder="请填写抹零金额" v-model="discount['20']"></el-input>
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input type="textarea" :rows="3" placeholder="请填写订单备注" v-model="orderInfo.remark"></el-input>
        </el-form-item>
        <el-form-item label="下单时间" v-if="canRepairOrder || reverse || recycle_order_id">
          <el-radio-group v-model="form.time">
            <div class="order-time">
              <el-radio label="1" @click="create_time = ''" v-if="!reverse">当前时间</el-radio>
              <el-radio label="1" @click="create_time = ''" v-else>下单时间</el-radio>
            </div>
            <div class="order-time">
              <el-radio label="2">自定义时间</el-radio>
              <el-date-picker v-if="form.time == '2'" v-model="create_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div style="padding-left: 20px">
        剩余应付：
        <span style="color: #e7541e; font-size: 26px">￥{{ surplusMoney }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSum" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>

    <ChooseNumber @onHideNumber="handleHideNumber" @onAddNumber="addNumberVisible = true" :dialogVisible="numberVisible" @onAdd="handleAdd" v-if="numberVisible" />

    <AddNumber v-if="addNumberVisible" :onlyAdd="true" @onHideAdd="handleHideAdd" />
    <ChooseOld @onHideOld="handleHideOld" :dialogVisible="oldVisible" @onOld="handleOld" @onOldRowList="setOldRowList" v-if="oldVisible" :oldRow="oldRow" :goods="newArrival" />

    <ChangePrice :dialogVisible="changeVisible" @onHideChange="handleHideChange" :currRow="currRow" :main_store_user_id="ratioList[0].store_user_id" :client_user_id="memberInfo.client_user_id" v-if="changeVisible" @onGoodData="handleChangePriceNew" @onRadio="handleRadio" :originRadio="originRadio" />

    <choose-goods :goodVisibel="goodVisibel" @close="goodVisibel = false" @getGoods="handleGoods" :formItemList="formItemList" :gift="gift" :selectGoods="newArrival" query_type="order_goods" v-if="goodVisibel" />

    <choose-goods :newArrival="newArrival" :selectGoods="gift" :goodVisibel="giftVisibel" @close="giftVisibel = false" @getGoods="handleGoods" :formItemList="formItemList" query_type="order_goods" v-if="giftVisibel" />
    <!-- type="order_goods" -->
    <el-dialog title="交易成功" :visible.sync="successVisible" width="25%" :show-close="false" :before-close="handleSuccessClose">
      <div class="icon">
        <i class="el-icon-success"></i>
      </div>
      <div class="success-list">
        <div>
          <span>应收金额</span>
          <span>￥{{ successInfo.money }}</span>
        </div>
        <div>
          <span>实收金额</span>
          <span style="color: #e7541e">￥{{ successInfo.money }}</span>
        </div>
        <!-- <div>
            <span>收款方式</span>
            <span>{{ successInfo.payText }}</span>
          </div>-->
        <div>
          <span>订单号</span>
          <span>{{ successInfo.order_number }}</span>
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleSuccessClose">查看详情</el-button> -->
        <el-button type="primary" v-print="printObj">本地打印</el-button>
        <el-button @click="yunPrint" :loading="yunLoading">云打印</el-button>
        <el-button @click="handleCustom">自定义打印</el-button>
        <el-button type="primary" @click="handleSuccessClose">返回列表</el-button>
      </span>
      <div v-show="false">
        <div id="piint2" v-html="htmlNode"></div>
      </div>
    </el-dialog>
    <el-dialog title="自定义打印" :show-close="false" :visible.sync="printVisible" width="30%" :before-close="handleClose">
      <Print :type="printType" :order_id="currRow.order_id || currRow.recycle_id" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSuccessClose">返回列表</el-button>
      </span>
    </el-dialog>

    <ChooseCard :dialogVisible="cardVisible" @onHideCard="cardVisible = false" @onChooseCard="handleChooseCard" v-if="cardVisible" :clearCard="clearCard" :card="card" :cardList="cardList" :money="total.orderMoney" />


    <ChoosePre :dialogVisible="preVisible" v-if="preVisible" :preList="preList" @onHidePre="preVisible = false" @onChoosePre="handleChoosePre" :cardId="pre.store_prestore_user_id" :money="total.orderMoney" />

    <Hand @onCloseHand="handVisible = false" :dialogVisible="handVisible" @onDetail="getDetail" />

    <Saving :dialogVisible="savingVisible" @onBack="handleSaving" :newArrival="newArrival" v-if="savingVisible" :storageMoneyInfo="storageMoneyInfo" @onChange="handleSavingChange" />
    <div class="print" style="width: 55%; color: #606266" v-show="false">
      <div class="btns">
        <el-button type="primary" v-print="printObj">本地打印</el-button>
        <el-button type="primary" plain @click="yunPrint">云打印</el-button>
      </div>
      <div id="print">
        <div class="title">{{ printData.hierarchy_name }}</div>
        <div class="print-list">
          <div class="item">
            <div class="left">分店电话：</div>
            <div class="print-right">{{ printData.shop_phone }}</div>
          </div>
          <div class="item">
            <div class="left">订单编号：</div>
            <div class="print-right">{{ printData.order_number }}</div>
          </div>
        </div>
        <div class="print-list">
          <div class="item">
            <div class="left">分店地址：</div>
            <div class="print-right">{{ printData.shop_address }}</div>
          </div>
          <div class="item">
            <div class="left">日期：</div>
            <div class="print-right">{{ printData.create_time }}</div>
          </div>
        </div>
        <div class="print-list">
          <div class="item">
            <div class="left">销售顾问：</div>
            <div class="right">{{ printData.main_store_name }} {{ printData.vice_store_name }}</div>
          </div>
          <div class="item">
            <div class="left">备注：</div>
            <div class="right">{{ printData.remark || "--" }}</div>
          </div>
        </div>
        <div class="my-table" v-if="printData1.length">
          <div class="table-header flex">
            <div style="flex: 2">货号</div>
            <div style="flex: 2">货品名称</div>
            <div style="flex: 1">金重(g)</div>
            <div style="flex: 1">数量</div>
            <div style="flex: 1">工费</div>
            <div style="flex: 1">金价</div>
            <div style="flex: 1">折扣</div>
            <div style="flex: 1">实收金额</div>
          </div>
          <div class="flex" v-for="(item, index) in printData1" :key="index + 'print'">
            <div style="flex: 2">{{ item.goods_number }}</div>
            <div style="flex: 2">{{ item.goods_name }}</div>
            <div style="flex: 1">{{ item.sale_type === 10 ? item.goldweight : "--" }}</div>
            <div style="flex: 1">{{ item.goods_pcs }}</div>
            <div style="flex: 1">{{ item.sale_cutfee }}</div>
            <div style="flex: 1">{{ item.sale_type === 10 ? item.goldprice : "--" }}</div>
            <div style="flex: 1">{{ item.discount }}</div>
            <div style="flex: 1">{{ item.actual_sale_price }}</div>
          </div>
          <div class="table-header flex">
            <div style="flex: 2">合计</div>
            <div style="flex: 2"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">{{ sumObj1.goods_pcs }}</div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">{{ sumObj1.actual_sale_price }}</div>
          </div>
        </div>
        <div class="my-table" style="margin-top: 10px" v-if="printData2.length">
          <div class="table-header flex">
            <div style="flex: 2">旧料货号</div>
            <div style="flex: 2">材质成色</div>
            <div style="flex: 1">金重(g)</div>
            <div style="flex: 1">数量</div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">旧料金额</div>
          </div>
          <div class="flex" v-for="(item, index) in printData2" :key="index + 'print'">
            <div style="flex: 2">{{ item.goods_number }}</div>
            <div style="flex: 2">{{ item.material_name }}</div>
            <div style="flex: 1">{{ item.sale_type === 10 ? item.goldweight : "--" }}</div>
            <div style="flex: 1">{{ item.goods_pcs }}</div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">{{ item.actual_sale_price }}</div>
          </div>
          <div class="table-header flex">
            <div style="flex: 2">合计</div>
            <div style="flex: 2"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">{{ sumObj2.goods_pcs }}</div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1"></div>
            <div style="flex: 1">{{ sumObj2.actual_sale_price }}</div>
          </div>
        </div>
        <div class="heji">
          <div class="item">
            <span class="left">收款方式：</span>
            <span>{{ printData.pay_type }}</span>
          </div>
          <div class="item">
            <span class="left">应收金额合计：</span>
            <span>{{ printData.paid_in_amount }}</span>
          </div>
        </div>
        <div class="heji">
          <div class="item">
            <span class="left">合计金额(大写)：</span>
            <span>{{ printData.pain_in_amount_dx }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="主销比例设置" :visible.sync="ratioVisible" width="30%">
      <div>
        多人销售-主销固定比例
        <el-input placeholder="请输入百分比" style="width: 200px; margin-left: 10px;" type="number" v-model="mainRatioCopy" @input="handleMainRatio">
          <template slot="append">%</template>
        </el-input>
      </div>
      <el-checkbox v-model="checkedCopy">自动计算提成比</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ratioVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import {getListReq as getPayListReq} from "@/api/system/settleConfig.js";
import numeral from "numeral";
import {getInfoReq} from "@/api/member/memberList/index";
import {
  getOrderInfoReq,
  printOrderReq,
  getRecycleOrderData,
  printRecycleOrderReq,
  repairOrderReq,
  resetRecycleDetailsReq
} from "@/api/order/offlineOrder/list";
import {post} from "@/utils/request";
import {
  getCanUseCostPriceReq,
  getCanSaleByCostReq,
  countOrderStorageReq,
  settingListReq,
  getAttrListReq,
  getEmployeeReq,
  createOrderReq,
  getAmountByIntegralReq,
  getCustomerDiscountReq,
  mountOrderReq,
  getMountOrderReq,
  getDiscountListReq,
  getPreListReq,
  getAmountReq,
  getIntegralByAmountReq,
  resetOrderDetailReq,
  resetOrderReq,
  getGoodsNumberStockReq,
  getOrderEmployeePercentReq,
  orderPercentSetReq,
  useDiscountReq,
  useDiscountNewReq
} from "@/api/order/offlineOrder/add";
import {getCashSetReq} from "@/api/system/settleConfig";
import goodsReq from "@/components/goods/chooseGoods/index";
import chooseGoods from "@/components/goods/chooseGoods/Index.vue";
import ChooseNumber from "./ChooseNumber.vue";
import ChooseOld from "./ChooseOld.vue";
import ChangePrice from "./ChangePrice.vue";
import ChooseCard from "./ChooseCard.vue";
import ChoosePre from "./ChoosePre.vue";
import Saving from "./Saving.vue";
import Hand from "./Hand.vue";
import __tag__ from "@/utils/tag.js";
import storage from "good-storage";
import Print from "@/components/print/Index.vue";
import {tableConfReq} from "@/components/tableConf/index.js";
import AddNumber from "@/views/member/memberManage/mineMember/Index";


export default {
  name: "Add",
  data() {
    this.tagList = __tag__;
    return {
      is_first_num:1,
      is_first:10,
      canUseCostPrice: false, // 能否使用批量成本价
      canSaleByCost: false, // 是否显示批量成本价
      newRowsLoading: false,
      total_new: 0,
      total_gift: 0,
      total_raw: 0,
      setPercent: false,
      checkedCopy: false,
      mainRatioCopy: "",
      ratioTimer: null,
      mainRatio: "", // 主销售比例
      checked: true, // 是否自动计算提成比
      ratioList: [{id: 1, ratio: "100.00", store_user_id: ""}],
      ratioVisible: false,
      addNumberVisible: false,
      discountMoneyShow: "-0.00",
      oldRow: {},
      payList: [], // 支付列表
      savingNum: 0, // 执行储值金的次数
      originRadio: 1,
      changeSaving: false, // 储值金标识
      storageMoneyInfo: {}, // 储值金信息
      settingList: [], // 抵扣比例列表
      savingVisible: false,
      useSaving: false,
      roundTag: false, // 当标识为true时四舍五入已经完成，防止watch进入死循环
      ext_params: 0, // 逢几进十
      ml_set: 1, // 1不抹零 2抹零
      create_time: "",
      canRepairOrder: true, // 补开订单的标识
      printVisible: false,
      printType: "", // 打印订单类型 type`    类别：10入库单 20销售单 30退货单  40回收单',
      htmlNode: "",
      yunLoading: false,
      printObj2: {
        id: "print2",
        popTitle: "销售订单"
      },
      printObj: {
        id: "print",
        popTitle: "销售订单"
      },
      printData: {},
      printData1: [],
      printData2: [],
      handVisible: false,
      amount: {},
      clearMax: false, // 抹零是否超过100
      preList: {
        available: [],
        unavailable: []
      },
      preVisible: false, // 预存
      changePriceNew: false, // 为true时 标识是改价引起的。此时货品变化watch会员折扣率不执行;
      clearCard: false, // 清空优惠券
      clearPre: false, // 清空预存

      mountOrderInfo: {
        orderInfo: {
          pay_amount: ""
        }
      }, // 挂单详情

      pre: {},

      card: [], // 当前选中的优惠券

      cardList: {
        available: [],
        unavailable: []
      }, // 优惠券列表
      mountLoading: false,
      mountOrder: false, // 当前是否挂单的标识
      currRow: {}, // 当前行
      loading2: false,
      cardVisible: false, // 卡券是否显示
      successInfo: {}, // 付款成功信息
      loading: false,
      surplusMoney: 0, // 剩余应还
      discount: {
        20: 0,
        40: 0
      }, // 组装后端需要数据格式
      orderInfo: {}, // 组装后端需要数据格式
      payInfo: {
        // 10: 0,
        // 20: 0,
        // 30: 0,
        // 40: 0,
        // 50: 0,
        // 70: 0,
      }, // 组装后端需要数据格式
      goodsList: [], // 组装后端需要数据格式
      goods_number: "", // 货品号
      // 订单总金额
      total: {
        orderMoney: 0, // 订单金额
        oldMoney: 0, // 旧料金额
        preMoney: 0, // 预存金额
        couponMoney: 0, // 优惠券折扣金额
        integralMoney: 0, // 积分抵扣金额
        clearMoney: 0, // 抹零金额
        memberMoney: 0, // 会员折扣金额
        actualMoney: 0, // 实收金额
        youhuiMoney: 0 // 整单优惠
      },
      tag: "", // 当前是筛选货品还是赠品的标志
      employeeList: [], // 员工列表
      memberInfo: {}, // 会员信息
      changeVisible: false, // 改价弹窗
      successVisible: false,
      settlementForm: {}, // 结算的数据
      settlementVisible: false,
      oldVisible: false,
      giftVisibel: false,
      goodVisibel: false,
      formItemList: [
        {
          label: "品类",
          placeholder: "请选择",
          attr: "goods_type_id",
          type: "treeSelect"
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input"
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input"
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input"
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input"
        },
        {
          label: "调仓单号",
          placeholder: "请填写调仓单号",
          attr: "allot_warehouse_number",
          type: "input"
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input"
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input"
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input"
        },
        {type: "hierarchy_id", label: "所属门店"},
        {type: "warehouse_id", label: "仓库"},
        {type: "101", label: "款式"},
        {type: "100", label: "材质成色"},
        {type: "103", label: "品牌"},
        {type: "sale_price", label: "标签价"},
        {type: "104", label: "净金重"},
        {type: "entry_time", label: "入库时间"}
        // {
        //   label: "调拨单号",
        //   placeholder: "请填写调拨单号",
        //   attr: "allot_id",
        //   type: "input",
        // },
      ],
      numberVisible: false, // 是否展示选择会员
      youhuiForm: {},
      gift: [], //  货品数据 增品
      giftRowList: [
        {field_text: "货号", field_alias: "goods_number"},
        {field_text: "品名", field_alias: "goods_name"},
        {field_text: "品类", field_alias: "goods_type_name"},
        {field_text: "数量", field_alias: "num"},
        {field_text: "净金重", field_alias: "104"},
        {field_text: "标签价(元)", field_alias: "sale_price"},
        {field_text: "销售价(元)", field_alias: "money"},
        {field_text: "仓库", field_alias: "sale_warehouse", t_width: "120px"}
      ],
      raw: [], //  旧料数据
      oldRowList: [
        {field_text: "货号", field_alias: "goods_number"},
        {field_text: "品名", field_alias: "goods_name"},
        {field_text: "品类", field_alias: "goods_type_name"},
        {field_text: "图片", field_alias: "goods_pic"},
        {field_text: "材质成色", field_alias: "good_material_name"},
        {field_text: "净金重", field_alias: "gold_weight"},
        {field_text: "金重(毛)", field_alias: "1446"},
        {field_text: "主石重", field_alias: "130"},
        {field_text: "总重", field_alias: "110"},
        {field_text: "回收单价(元/克)", field_alias: "1449"},
        {field_text: "数量", field_alias: "pcs"},
        {field_text: "折旧费(元/克)", field_alias: "114"},
        {field_text: "补金单价(元/克)", field_alias: "1450"},
        {field_text: "回收价格", field_alias: "recycle_price"},
        {field_text: "关联商品", field_alias: "exchange_goods_name"}
      ],
      newArrival: [], //  货品数据
      newRowList: [
        // { field_text: "货号", field_alias: "goods_number" },
        // { field_text: "品名", field_alias: "goods_name" },
        // { field_text: "品类", field_alias: "goods_type_name" },
        // { field_text: "材质成色", field_alias: "100" },
        // { field_text: "净金重(g)", field_alias: "104" },
        // { field_text: "总重", field_alias: "totalweight" },
        // { field_text: "总重合计", field_alias: "sum_totalweight" },
        // { field_text: "销售方式", field_alias: "sales_type" },
        // { field_text: "今日金价", field_alias: "gold_price", t_width: "150px" },
        // { field_text: "销售工费类型", field_alias: "185" },
        // { field_text: "销售工费(元)", field_alias: "184" },
        // // { field_text: '货品类型', field_alias: 'goods_number_type' },
        // { field_text: "数量", field_alias: "num" },
        // { field_text: "标签价(元)", field_alias: "sale_price" },
        // { field_text: "标签价合计(元)", field_alias: "sum_sale_price" },
        // { field_text: "折扣率(折)", field_alias: "discount" },
        // { field_text: "销售价(元)", field_alias: "actual_sale_price" },
        // { field_text: "销售价合计(元)", field_alias: "sum_actual_sale_price" },
        // { field_text: "仓库", field_alias: "sale_warehouse", t_width: "120px" },
      ],

      radio: "",
      formInline: {},
      form: {
        time: "1"
      },
      activeNames: ["1", "2", "3"],
      timer: null, // 计时器
      integralInfo: 0, // 积分换算
      scaleOut: false // 积分抵扣金额是否超过订单金额标识
    };
  },
  computed: {
    newArrivalComputed() {
      return JSON.parse(JSON.stringify(this.newArrival));
    },
    totalComputed() {
      return JSON.parse(JSON.stringify(this.total));
    }
  },
  // 监听货品，旧料和赠品改变，计算金额
  watch: {
    //储值金
    storageMoneyInfo: {
      handler(val) {
        this.handleTotal();
        this.handleSurplus();
      },
      deep: true
    },

    totalComputed: {
      handler(val, old) {
        // 如果订单金额发生了改变且已经选择了优惠券
        if (val.orderMoney !== old.orderMoney && this.memberInfo.client_user_id) {
          if (this.pre.store_prestore_user_id) {
            this.reselectPre();
          } else {
            this.getPreList();
          }
        }
        if (val.orderMoney !== old.orderMoney && this.memberInfo.client_user_id) {
          // 重新选择优惠券
          if (this.card.length > 0) {
            this.reselectCard();
          } else {
            this.getDiscountList();
          }
        }
        if (val.actualMoney && this.memberInfo.client_user_id) {
          // this.getPreList();
          this.getAmount();
        }
        // 如果订单实收金额发生了改变且已经选择了优惠券
        // 实收发生改变且不是因为选了优惠券改变的
        // if (val.actualMoney !== old.actualMoney && this.card.client_card_voucher_id) {
        //   this.reselectCard();
        // }
      },
      deep: true
    },

    memberInfo: {
      handler(val, prev) {
        if (this.memberInfo.client_user_id && this.newArrival.length) {
          const goods_info = [];
          this.newArrival.forEach(item => {
            const obj = {
              goods_type_id: item.goods_type_id,
              goods_id: item.goods_id,
              actual_sale_price: item.actual_sale_price,
              pcs: item.pcs || item.num,
              storage_money: this.savingNum === 1 ? item.storage_money : ""
            };
            goods_info.push(obj);
          });
          this.countOrderStorage(JSON.stringify(goods_info));
          // if (!this.reverse) {
          //   this.countOrderStorage(JSON.stringify(goods_info));
          // }
        }

        if (this.memberInfo.client_user_id) {
          this.getPreList();
          // 如果会员id改变且存在，获取预存列表
          if (this.total.actualMoney) {
            this.getAmount();
          }

          // 重新选择优惠券
          if (this.card.length > 0 && prev.client_user_id) {
            this.reselectCard();
          } else {
            this.getDiscountList();
          }
          // 选了会员 折扣 工费  今日金价 全部重置
          // this.newArrival.forEach((item) => {
          //   if (item.discount === 10) {
          //     // if (item.originG) {
          //     //   item['184'] = item.originG;
          //     // }
          //     this.getCustomerDiscount(
          //       {
          //         client_user_id: this.memberInfo.client_user_id,
          //         goods_id: item.goods_id,
          //       },
          //       (data, goods_id) => {
          //         this.newArrival.forEach((newItem) => {
          //           if (newItem.goods_id === goods_id) {
          //             newItem.discount = data;
          //             // eslint-disable-next-line no-mixed-operators
          //             newItem.actual_sale_price = numeral(
          //               (Number(newItem.sale_price) * data) / 10
          //             ).format("0.00");
          //           }
          //         });
          //       }
          //     );
          //   }
          // });
        }
      }
    },
    integralInfo: {
      handler() {
        this.handleTotal();
        this.handleSurplus();
      }
    },
    newArrivalComputed: {
      // deep: true,
      handler(newVal) {
        this.handleTotalNumber();
        // 抹零重置
        this.total.clearMoney = 0;
        const orderInfo = {...this.orderInfo};
        orderInfo.use_money = 0;
        this.orderInfo = orderInfo;
        this.integralInfo = 0;
        this.orderInfo.use_inegral = 0;
        if (this.memberInfo.client_user_id && this.newArrival.length) {
          if (!this.changeSaving) {
            const goods_info = [];
            newVal.forEach(item => {
              const obj = {
                goods_type_id: item.goods_type_id,
                goods_id: item.goods_id,
                actual_sale_price: item.actual_sale_price,
                pcs: item.pcs || item.num,
                storage_money:
                  this.savingNum === 1 ? (item.storage_money ? item.storage_money : "") : ""
              };
              goods_info.push(obj);
            });
            this.countOrderStorage(JSON.stringify(goods_info));
            this.changeSaving = true;
          } else {
            this.changeSaving = false;
          }
        }
        if (!this.newArrival.length) {
          this.useSaving = false;
        }
        // 请求接口获得折扣
        // if (!this.changePriceNew) {
        //   if (this.memberInfo.client_user_id) {
        //     newVal.forEach((item) => {
        //       if (item.discount === 10) {
        //         this.getCustomerDiscount(
        //           {
        //             client_user_id: this.memberInfo.client_user_id,
        //             goods_id: item.goods_id,
        //           },
        //           (data, goods_id) => {
        //             this.newArrival.forEach((newItem) => {
        //               if (newItem.goods_id === goods_id) {
        //                 newItem.discount = data;
        //                 // eslint-disable-next-line no-mixed-operators
        //                 newItem.actual_sale_price = numeral(
        //                   (Number(newItem.sale_price) * data) / 10
        //                 ).format("0.00");
        //               }
        //             });
        //           }
        //         );
        //       }
        //     });
        //   }
        // } else {
        //   setTimeout(() => {
        //     this.changePriceNew = false;
        //   }, 1000);
        // }
        this.handleTotal();
        this.handleSurplus();
        // if (!this.ext_params) {
        //   this.handleTotal();
        //   this.handleSurplus();
        //   return;
        // }
        // if (this.roundTag) {
        //   this.handleTotal();
        //   this.handleSurplus();
        //   // roundTag为true标识四舍五入已经完成
        //   // return;
        // }
        // 货品改变时四舍五入
        // setTimeout(() => {
        //   this.changePriceNew = true;
        //   this.roundTag = true;
        //   this.newArrival.forEach((newItem, index) => {
        //     // 如果货品的销售方式是销售按件
        //     if (newItem.sales_type === 20) {
        //       if (newItem.discount != 10) {
        //         const arr = newItem.actual_sale_price.split(".");
        //         if (arr.length === 2) {
        //           let actual_sale_price;
        //           if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
        //             // 四舍五入进1
        //             // 小于标签价才能五入
        //             if (Number(arr[0]) + 1 < newItem.sale_price) {
        //               actual_sale_price = Number(arr[0]) + 1 + ".00";
        //             } else {
        //               actual_sale_price = arr[0] + "." + arr[1];
        //             }
        //           } else {
        //             actual_sale_price = Number(arr[0]) + ".00";
        //           }
        //           this.$set(this.newArrival, index, {
        //             ...newItem,
        //             actual_sale_price: actual_sale_price,
        //           });
        //         }
        //       }
        //     } else {
        //       // 如果货品的销售方式是销售按克，一开始就要四舍五入
        //       const arr = String(newItem.actual_sale_price).split(".");
        //       if (arr.length === 2) {
        //         let actual_sale_price;
        //         if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
        //           // 四舍五入进1
        //           // 小于标签价才能五入
        //           if (Number(arr[0]) + 1 < newItem.sale_price) {
        //             actual_sale_price = Number(arr[0]) + 1 + ".00";
        //           } else {
        //             actual_sale_price = arr[0] + "." + arr[1];
        //           }
        //         } else {
        //           actual_sale_price = Number(arr[0]) + ".00";
        //         }
        //         this.$set(this.newArrival, index, {
        //           ...newItem,
        //           actual_sale_price: actual_sale_price,
        //         });
        //       }
        //     }
        //   });
        //   this.handleTotal();
        //   this.handleSurplus();
        // }, 500);
      }
    },
    raw: {
      deep: true,
      handler() {
        this.handleTotalNumber();
        // 抹零重置
        this.total.clearMoney = 0;
        const orderInfo = {...this.orderInfo};
        orderInfo.use_money = 0;
        this.orderInfo = orderInfo;
        this.integralInfo = 0;
        this.orderInfo.use_inegral = 0;
        this.handleTotal();
        this.handleSurplus();
      }
    },
    gift: {
      deep: true,
      handler() {
        this.handleTotalNumber();
        this.handleTotal();
        this.handleSurplus();
      }
    },
    // 监听结算时收款方式计算剩余应还
    payInfo: {
      deep: true,
      handler() {
        this.handleSurplus();
      }
    },
    discount: {
      deep: true,
      handler() {
        this.handleTotal();
        this.handleSurplus();
      }
    },
    total: {
      deep: true,
      handler() {
        // this.handleTotal();
        this.handleSurplus();
      }
    }
  },
  components: {
    AddNumber,
    Saving,
    ChooseNumber,
    chooseGoods,
    ChooseOld,
    ChangePrice,
    ChooseCard,
    ChoosePre,
    Hand,
    Print
  },
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    order_number: {
      type: String,
      default: ""
    },
    order_id: {
      type: String | Number,
      default: ""
    },
    recycle_order_id: {
      type: String | Number,
      default: ""
    }
  },
  created() {
    // 支付方式列表
    this.getPayList();

    //获取所有的商家信息
    this.getOldRowList();

    // 获取员工列表
    this.getEmployee();

    this.getSettingList();

    // 挂单
    if (this.order_number && !this.reverse) {
      this.getMountOrder();
    }

    // 销售单反确认
    if (this.order_number && this.reverse) {
      this.savingNum = 1;
      this.getResetOrderDetail();
    }


    // 回收订单反确认
    if (this.recycle_order_id) {
      this.resetRecycleDetails();
    }
    // 获取优惠券列表
    // this.getDiscountList();

    // 是否有补开订单的权限
    this.repairOrder();

    this.getCashSet();

    this.getRows();

    // 获取员工销售比例设置
    this.getOrderEmployeePercent();

    this.getCanSaleByCost();
  },
  // activated() {
  //   this.getCashSet();
  // },

  methods: {
    getCanUseCostPrice() {
      getCanUseCostPriceReq().then(res => {
        if (res.code === 1) {
          if (res.data.isUse) {
            this.canUseCostPrice = true;
          }
        }
      });
    },
    getCanSaleByCost() {
      getCanSaleByCostReq().then(res => {
        if (res.code === 1) {
          this.canSaleByCost = res.data;
          this.getCanUseCostPrice();
        }
      });
    },

    handleTotalChange() {
      if (!this.newArrival.length) {
        this.$message.warning("请先选择货品");
        return;
      }
      if (this.card.coupon_log_ids) {
        this.$message.warning("请先删除优惠券");
        return;
      }
      this.newArrival = this.newArrival.map(item => {
        return {
          ...item,
          actual_sale_price: item.cost_price,
          sum_actual_sale_price: numeral(Number(item.cost_price) * Number(item.num)).format("0.00"),
          discount: numeral((Number(item.cost_price) / Number(item.sale_price)) * 10).format("0.00")
        };
      });
    },

    handleSelectAll(e) {
      e.currentTarget.select();
    },

    getRows() {
      this.newRowsLoading = true;
      tableConfReq("order_goods_title", 0).then(res => {
        if (res.code === 1) {
          this.newRowsLoading = false;
          this.newRowList = res.data;
        }
      });
    },

    // 计算货品 旧料  礼物的总数（包括一码多货数量)
    handleTotalNumber() {
      this.total_new = 0;
      this.total_raw = 0;
      this.total_gift = 0;
      if (this.newArrival.length) {
        let total_new = 0;
        this.newArrival.forEach(item => {
          total_new += Number(item.num);
        });
        this.total_new = total_new;
      }
      if (this.raw.length) {
        let total_raw = 0;
        this.raw.forEach(item => {
          total_raw += Number(item.pcs);
        });
        this.total_raw = total_raw;
      }
      if (this.gift.length) {
        let total_gift = 0;
        this.gift.forEach(item => {
          total_gift += Number(item.num || item.pcs);
        });
        this.total_gift = total_gift;
      }
    },
    // 限制主销售输入
    handleMainRatio(e) {
      if (!e) {
        this.mainRatioCopy = "0.00";
      } else {
        if (Number(e) > 100) {
          this.mainRatioCopy = "100.00";
        }
      }
    },
    handleInputRatio(e, Index) {
      if (!this.checked) {
        return;
      }
      // 输入的是主销售
      if (!Index) {
        let value = e.target.value;
        if (!e.target.value) {
          value = "0.00";
        }
        if (Number(e.target.value) > 100) {
          value = "100.00";
        }
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
        if (this.ratioTimer) {
          clearInterval(this.ratioTimer);
        }
        this.ratioTimer = setTimeout(() => {
          const ratio = numeral((100 - Number(value)) / (this.ratioList.length - 1)).format("0.00");
          this.ratioList.forEach((item, index) => {
            let obj = {};
            if (index === 0) {
              obj = {
                id: item.id,
                ratio: value,
                store_user_id: item.store_user_id
              };
            } else {
              obj = {
                id: item.id,
                ratio:
                  index === this.ratioList.length - 1
                    ? numeral(100 - Number(value) - ratio * (this.ratioList.length - 2)).format(
                      "0.00"
                    )
                    : ratio,
                store_user_id: item.store_user_id
              };
            }
            this.$set(this.ratioList, index, obj);
          });
        }, 500);
      } else {
        // 输入的是辅销售
        let value = e.target.value;
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
        // 如果只有一个主销一个辅销
        if (this.ratioList.length === 2) {
          if (!e.target.value) {
            value = "0.00";
          }
          if (Number(value) > 100) {
            value = "100.00";
          }
          let obj = {};
          this.$set(this.ratioList, 0, {
            id: this.ratioList[0].id,
            ratio: numeral(100 - Number(value)).format("0.00"),
            store_user_id: this.ratioList[0].store_user_id
          });
          this.$set(this.ratioList, 1, {
            id: this.ratioList[1].id,
            ratio: value,
            store_user_id: this.ratioList[1].store_user_id
          });
          return;
        }
        if (!e.target.value) {
          value = "0.00";
        }
        if (Number(e.target.value) > 100 - Number(this.ratioList[0].ratio)) {
          value = numeral(100 - Number(this.ratioList[0].ratio)).format("0.00");
        }
        if (this.ratioTimer) {
          clearInterval(this.ratioTimer);
        }
        this.ratioTimer = setTimeout(() => {
          this.ratioList.forEach((item, index) => {
            let obj = {};
            if (index === 0) {
              obj = {
                id: item.id,
                ratio: item.ratio,
                store_user_id: item.store_user_id
              };
            } else if (index === Index) {
              obj = {
                id: item.id,
                ratio: value,
                store_user_id: item.store_user_id
              };
            } else {
              const ratio = numeral(
                (100 - Number(this.ratioList[0].ratio) - Number(value)) /
                (this.ratioList.length - 2)
              ).format("0.00");
              if (index !== this.ratioList.length - 1) {
                obj = {
                  id: item.id,
                  ratio,
                  store_user_id: item.store_user_id
                };
              } else {
                // 最后一个需要自己减
                obj = {
                  id: item.id,
                  ratio: numeral(
                    100 -
                    Number(this.ratioList[0].ratio) -
                    Number(value) -
                    Number(ratio) * (this.ratioList.length - 3)
                  ).format("0.00"),
                  store_user_id: item.store_user_id
                };
              }
            }
            this.$set(this.ratioList, index, obj);
          });
        }, 500);
      }
    },
    // 销售信息是否重复
    checkRatio() {
      const obj = {};
      for (let i = 0; i < this.ratioList.length; i += 1) {
        const item = this.ratioList[i];
      }
    },
    // 设置员工销售比
    handleSet() {
      orderPercentSetReq({
        fix_percent: this.mainRatioCopy,
        auto_percent: this.checkedCopy ? 1 : 0
      }).then(res => {
        this.ratioVisible = false;
        this.getOrderEmployeePercent(() => {
          if (this.checked) {
            const ratio = numeral(
              (100 - this.ratioList[0].ratio) / (this.ratioList.length - 1)
            ).format("0.00");
            this.ratioList = this.ratioList.map((item, index) => {
              let obj = {};
              if (index === 0) {
                obj = {
                  id: item.id,
                  ratio: this.mainRatio,
                  store_user_id: item.store_user_id
                };
              } else {
                obj = {
                  id: item.id,
                  ratio:
                    index === this.ratioList.length - 1
                      ? numeral(
                        100 - this.ratioList[0].ratio - ratio * (this.ratioList.length - 2)
                      ).format("0.00")
                      : ratio,
                  store_user_id: item.store_user_id
                };
              }
              return obj;
            });
          }
        });
      });
    },
    // 获取员工销售比例设置
    getOrderEmployeePercent(callback) {
      getOrderEmployeePercentReq().then(res => {
        if (res.code === 1) {
          this.setPercent = true;
          // 是否自动计算
          if (res.data.auto_percent) {
            this.checked = true;
          } else {
            this.checked = false;
          }
          this.mainRatio = numeral(res.data.fix_percent).format("0.00");
          this.ratioList[0].ratio = this.mainRatio;
          if (callback) {
            callback();
          }
        }
      });
    },
    // 删除销售
    handleDeleSaler(index) {
      // 如果删除后只剩下主销售, 那么是百分之百
      this.ratioList.splice(index, 1);
      if (this.ratioList.length === 1) {
        this.ratioList[0].ratio = "100.00";
      }
      // 自动计算
      if (this.checked) {
        const ratio = numeral((100 - Number(this.mainRatio)) / (this.ratioList.length - 1)).format(
          "0.00"
        );
        this.ratioList.forEach((item, index) => {
          let obj = {};
          if (index === 0) {
            obj = {
              id: item.id,
              ratio: this.mainRatio,
              store_user_id: item.store_user_id
            };
          } else {
            obj = {
              id: item.id,
              ratio:
                index === this.ratioList.length - 1
                  ? numeral(
                    100 - this.ratioList[0].ratio - ratio * (this.ratioList.length - 2)
                  ).format("0.00")
                  : ratio,
              store_user_id: item.store_user_id
            };
          }
          this.$set(this.ratioList, index, obj);
        });
      }
    },
    // 添加销售
    handleAddSaler() {
      // 计算
      if (this.checked) {
        const ratio = numeral((100 - this.mainRatio) / this.ratioList.length).format("0.00");
        // 需要判断能否除得尽
        this.ratioList.forEach((item, index) => {
          let obj = {};
          if (index === 0) {
            obj = {
              id: item.id,
              ratio: this.mainRatio,
              store_user_id: item.store_user_id
            };
          } else {
            obj = {
              id: item.id,
              ratio,
              store_user_id: item.store_user_id
            };
          }
          this.$set(this.ratioList, index, obj);
        });
        this.ratioList.push({
          id: this.ratioList[this.ratioList.length - 1].id + 1,
          ratio: numeral(
            100 - this.ratioList[0].ratio - ratio * (this.ratioList.length - 1)
          ).format("0.00"),
          store_user_id: ""
        });
      } else {
        this.ratioList.push({
          id: this.ratioList[this.ratioList.length - 1].id + 1,
          ratio: "0.00",
          store_user_id: ""
        });
      }
    },
    // 会员
    handleHideAdd(data) {
      this.addNumberVisible = false;
      if (data) {
        getInfoReq(data).then(res => {
          if (res.code === 1) {
            this.memberInfo = res.data.user_info;
          }
        });
      }
    },
    // 编辑
    handleEdit(row) {
      this.oldVisible = true;
      this.oldRow = {...row};
    },
    // 回收单反确认详情
    resetRecycleDetails() {
      this.newArrival = [];
      this.gift = [];
      this.raw = [];
      resetRecycleDetailsReq({
        recycle_order_id: this.recycle_order_id
      })
        .then(res => {
          if (res.code === 1) {
            this.mountLoading = false;
            const {data} = res;
            this.mountOrderInfo = data;
            // 主销售和负销售
            this.form = {
              main_store_user_id: data.orderInfo.main_store_user_id,
              vice_store_user_id: data.orderInfo.vice_store_user_id,
              time: "1"
            };
            this.ratioList = data.orderInfo.ratioList;
            // data.goodsList.newArrival &&
            //   (this.newArrival = data.goodsList.newArrival);
            // data.goodsList.gift && (this.gift = data.goodsList.gift);
            data.goodsList.raw && (this.raw = data.goodsList.raw);
            this.orderInfo = {
              use_inegral: data.orderInfo.use_inegral
            };
            if (data.goodsList.newArrival) {
              data.goodsList.newArrival.forEach(good => {
                getGoodsNumberStockReq({
                  goods_number: good.goods_number,
                  order_id: this.order_id
                }).then(result => {
                  if (result.code === 1) {
                    if (result.data.length) {
                      // eslint-disable-next-line no-param-reassign
                      result.data = result.data.map(item => {
                        return {
                          ...item,
                          warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                        };
                      });
                      good = {
                        ...good,
                        stockList: result.data,
                        num: good.pcs,
                        changeNum: true
                      };
                    } else {
                      good = {
                        ...good,
                        stockList: [],
                        num: good.pcs,
                        changeNum: true
                      };
                    }
                    this.newArrival.push(good);
                  }
                });
              });
            }
            if (data.goodsList.gift) {
              data.goodsList.gift.forEach(good => {
                getGoodsNumberStockReq({
                  goods_number: good.goods_number,
                  order_id: this.order_id
                }).then(result => {
                  if (result.code === 1) {
                    if (result.data.length) {
                      result.data = result.data.map(item => {
                        return {
                          ...item,
                          warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                        };
                      });
                      good = {...good, stockList: result.data};
                    } else {
                      good = {...good, stockList: []};
                    }
                    this.gift.push(good);
                  }
                });
              });
            }
            // this.newArrival.forEach((item) => {
            //   getGoodsNumberStockReq({
            //     goods_number: item.goods_number,
            //   })
            //     .then((result) => {
            //       if (result.code === 1) {
            //         item.stockList = result.data;
            //       }
            //     });
            //   item.num = item.pcs;
            //   item.changeNum = true;
            // })
            if (Number(data.discount["80"])) {
              this.useSaving = true;
              this.storageMoneyInfo.use_money = data.discount["80"];
            }
            // 整单优惠
            if (Number(data.discount["70"])) {
              this.total.youhuiMoney = data.discount["70"];
            }
            setTimeout(() => {
              // 抹零金额
              if (Number(data.discount["20"])) {
                this.total.clearMoney = data.discount["20"];
              }
              // 积分
              if (Number(data.discount["50"])) {
                this.orderInfo.use_money = data.discount["50"];
                this.integralInfo = data.discount["50"];
                this.orderInfo.use_inegral = data.orderInfo.use_inegral;
              }
              this.handleTotal();
              // 根据会员id请求会员详情
              if (data.orderInfo.client_user_id) {
                this.getMemberInfo({client_user_id: data.orderInfo.client_user_id}, () => {
                  this.getAmountByIntegral(this.orderInfo.use_money);
                });
              }
            }, 500);
          }
        })
        .catch(() => {
          this.mountLoading = false;
        });
    },
    getPayList() {
      getPayListReq({
        status: 10
      }).then(res => {
        if (res.code === 1) {
          this.payList = res.data;
        }
      });
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex;
    },
    getCashSet() {
      getCashSetReq().then(res => {
        if (res.code === 1) {
          if (res.data.switch_params === 2) {
            res.data.ext_params = 5;
          }
          this.ext_params = res.data.ext_params;
          this.ml_set = res.data.ml_set;
        }
      });
    },

    handleRadio(radio) {
      this.originRadio = radio;
    },
    handleSavingChange(params) {
      this.savingVisible = false;
      this.changeSaving = true;
      this.storageMoneyInfo.use_money = params.use_money;
      this.newArrival = params.goods;
      this.handleTotal();
    },

    // 计算储值金
    countOrderStorage(goods_info) {
      countOrderStorageReq({
        client_user_id: this.memberInfo.client_user_id,
        goods_info,
        order_id: this.order_id
      }).then(res => {
        if (res.code === 1) {
          this.savingNum += 1;
          this.storageMoneyInfo = res.data;
          this.newArrival.forEach(item => {
            res.data.goods_info.forEach(good => {
              if (item.goods_id === good.goods_id) {
                item.storage_money = good.storage_money;
                item.max_discount_money = good.max_discount_money;
              }
            });
          });
          let use = 0;
          res.data.goods_info.forEach(good => {
            use += Number(good.storage_money) * Number(good.pcs);
          });
          this.storageMoneyInfo.use_money = numeral(use).format("0.00");
        }
      });
    },

    // 获取品类抵扣比例
    getSettingList() {
      settingListReq().then(res => {
        if (res.code === 1) {
          this.settingList = res.data;
        }
      });
    },

    handleChangeSaving() {
      if (!this.memberInfo.client_user_id) {
        this.useSaving = false;
        this.$message.warning("请先选择会员");
        return;
      }
      if (!this.newArrival.length) {
        this.useSaving = false;
        this.$message.warning("请先选择货品");
      }
      this.handleTotal();
    },

    handleSaving() {
      this.savingVisible = false;
    },

    handleOrigin() {
      this.$confirm("确定要恢复原订单数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (!this.recycle_order_id) {
            // 销售单反确认
            this.getResetOrderDetail();
          } else {
            // 回收单反确认
            this.resetRecycleDetails();
          }
        })
        .catch(() => {
        });
    },

    // 回填优惠卷
    getResetOrderDetail() {
      this.newArrival = [];
      this.raw = [];
      this.gift = [];
      resetOrderDetailReq({
        order_id: this.order_id
      })
        .then(res => {
          if (res.code === 1) {
            this.mountLoading = false;
            const { data } = res;
            this.mountOrderInfo = data;
            // 主销售和负销售
            this.form = {
              main_store_user_id: data.orderInfo.main_store_user_id,
              vice_store_user_id: data.orderInfo.vice_store_user_id,
              time: "1"
            };
            // 销售比例
            this.ratioList = data.orderInfo.ratioList;
            // data.goodsList.newArrival &&
            //   (this.newArrival = data.goodsList.newArrival);
            // data.goodsList.gift && (this.gift = data.goodsList.gift);
            data.goodsList.raw && (this.raw = data.goodsList.raw);
            this.orderInfo = {
              use_inegral: data.orderInfo.use_inegral
            };
            //选中的优惠券
            data.coupon_log_ids.forEach(item=>this.card.push(item));

            //货品
            if (data.goodsList.newArrival) {
              data.goodsList.newArrival.forEach(good => {
                getGoodsNumberStockReq({
                  goods_number: good.goods_number,
                  order_id: this.order_id
                }).then(result => {
                  if (result.code === 1) {
                    if (result.data.length) {
                      // eslint-disable-next-line no-param-reassign
                      result.data = result.data.map(item => {
                        return {
                          ...item,
                          warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                        };
                      });
                      good = {
                        ...good,
                        stockList: result.data,
                        actual_sale_price_2:(numeral(good.sum_actual_sale_price/good.pcs).format("0.00")),
                        sum_actual_sale_price:numeral(good.sum_actual_sale_price).format("0.00"),
                        card_discount:numeral(good.card_discount).format("0.00"),
                        num: good.pcs,
                        changeNum: true
                      };
                    } else {
                      good = {
                        ...good,
                        stockList: [],
                        num: good.pcs,
                        changeNum: true
                      };
                    }
                    this.newArrival.push(good);
                  }
                });
              });
            }
            if (data.goodsList.gift) {
              data.goodsList.gift.forEach(good => {
                getGoodsNumberStockReq({
                  goods_number: good.goods_number,
                  order_id: this.order_id
                }).then(result => {
                  if (result.code === 1) {
                    if (result.data.length) {
                      result.data = result.data.map(item => {
                        return {
                          ...item,
                          num:22,
                          warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                        };
                      });
                      good = { ...good, stockList: result.data };
                    } else {
                      good = { ...good, stockList: [] };
                    }
                    this.gift.push(good);
                  }
                });
              });
            }
            // this.newArrival.forEach((item) => {
            //   getGoodsNumberStockReq({
            //     goods_number: item.goods_number,
            //   })
            //     .then((result) => {
            //       if (result.code === 1) {
            //         item.stockList = result.data;
            //       }
            //     });
            //   item.num = item.pcs;
            //   item.changeNum = true;
            // })
            //回收金额
            if (!Number(data.discount["10"])) data.discount["10"] = 0;

            //预存金额
            if (!Number(data.discount["30"])) data.discount["30"] = 0;

            //折扣金额
            if (!Number(data.discount["60"])) data.discount["60"] = 0;

            //储值金
            if (Number(data.discount["80"])) {
              this.useSaving = true;
              this.storageMoneyInfo.use_money = data.discount["80"];
            }else{
              data.discount["80"] = 0;
            }
            // 整单优惠
            if (Number(data.discount["70"])) {
              this.total.youhuiMoney = data.discount["70"];
            }else{
              data.discount["70"] = 0;
            }
            setTimeout(() => {
              // 抹零金额
              if (Number(data.discount["20"])) {
                this.total.clearMoney = data.discount["20"];
              }else{
                data.discount["20"] = 0;
              }
              // 积分
              if (Number(data.discount["50"])) {
                this.orderInfo.use_money = data.discount["50"];
                this.integralInfo = data.discount["50"];
                this.orderInfo.use_inegral = data.orderInfo.use_inegral;
              }else{
                data.discount["50"] = 0;
              }
              //优惠券
              if(Number(data.discount["40"])){
                this.total.couponMoney = data.discount["40"];
                // this.discount = data.discount;
              }else{
                data.discount["40"] = 0;
              }

              this.discount = {...data.discount}
              this.handleTotal();
              // 根据会员id请求会员详情
              if (data.orderInfo.client_user_id) {
                this.getMemberInfo(
                  { client_user_id: data.orderInfo.client_user_id, original_order_id: this.order_id },
                  () => {
                    this.getAmountByIntegral(this.orderInfo.use_money);
                  }
                );
              }
            }, 500);
          }
        })
        .catch(() => {
          this.mountLoading = false;
        });
    },

    // 是否有补开订单的权限
    repairOrder() {
      repairOrderReq().then(res => {
        if (res.code === 1) {
          if (res.data.auth) {
            this.canRepairOrder = true;
          } else {
            this.canRepairOrder = false;
          }
        }
      });
    },
    handleCustom() {
      this.successVisible = false;
      this.printVisible = true;
    },
    yunPrint() {
      this.yunLoading = true;
      if (this.currRow.order_id) {
        printOrderReq({
          order_id: this.currRow.order_id
        })
          .then(res => {
            this.yunLoading = false;
            this.$notify({
              title: "云打印",
              message: res.return_data
            });
          })
          .catch(() => {
            this.yunLoading = false;
          });
      }
      if (this.currRow.recycle_id) {
        printRecycleOrderReq({
          recycle_order_id: this.currRow.recycle_id
        })
          .then(res => {
            this.yunLoading = false;
            this.$notify({
              title: "云打印",
              message: res.return_data
            });
          })
          .catch(() => {
            this.yunLoading = false;
          });
      }
    },
    getSums() {
      const obj = {
        goods_pcs: 0,
        actual_sale_price: 0
      };
      this.printData1.forEach(item => {
        obj.goods_pcs += Number(item.goods_pcs);
        obj.actual_sale_price += Number(item.actual_sale_price);
      });
      obj.actual_sale_price = numeral(obj.actual_sale_price).format("0.00");
      this.sumObj1 = obj;
    },
    getSums2() {
      const obj = {
        goods_pcs: 0,
        actual_sale_price: 0
      };
      this.printData2.forEach(item => {
        obj.goods_pcs += Number(item.goods_pcs);
        obj.actual_sale_price += Number(item.actual_sale_price);
      });
      obj.actual_sale_price = numeral(obj.actual_sale_price).format("0.00");
      this.sumObj2 = obj;
    },
    handleNum(e, index) {
      e = Number(e.replace(/\D+/, ""));
      const item = this.newArrival[index];
      item.num = e;
      item.pcs = e;
      item.sum_totalweight = numeral(item.num * item.totalweight).format("0.00");
      item.sum_sale_price = numeral(item.num * item.sale_price).format("0.00");

      if (item.card_discount) {
        item.sum_actual_sale_price = numeral(item.num * item.actual_sale_price).format("0.00");
      } else {
        item.sum_actual_sale_price = numeral(item.num * item.actual_sale_price).format("0.00");
      }


      this.$set(this.newArrival, index, item);
      item.changeNum = true;
      this.roundTag = false;
      if (this.memberInfo.client_user_id) {
        const goods_info = [];
        this.newArrival.forEach(item => {
          const obj = {
            goods_type_id: item.goods_type_id,
            goods_id: item.goods_id,
            actual_sale_price: item.actual_sale_price,
            pcs: item.pcs || item.num,
            storage_money: this.savingNum === 1 ? item.storage_money : ""
          };
          goods_info.push(obj);
        });
        this.countOrderStorage(JSON.stringify(goods_info));
      }
    },
    // 修改今日金价
    handleGoldPrice(e, index) {
      const item = this.newArrival[index];
      var sale_cost_rmb
      if(typeof item["184"]=="undefined"){
        sale_cost_rmb=0;
      }else{
        sale_cost_rmb=Number(item["184"]);
      }
      var originG
      if(typeof item.originG=="undefined"){
        originG=0;
      }else{
        originG=Number(item.originG);
      }
      console.log('item.originG',item.originG);
      if (typeof item["185"]!=="undefined" && item["185"] === "按克") {
        item.sale_price = (Number(e) + originG) * Number(item["104"]);
      }
      if (typeof item["185"]!=="undefined" && item["185"] === "按件") {
        item.sale_price = Number(e) * Number(item["104"]) + originG;
      }
      if(item["185"] == "" && Number(originG) == 0){
        item.sale_price = Number(e) * Number(item["104"]);
      }
      if(typeof item["185"]=="undefined" || typeof item["184"]=="undefined" ||Number(item["184"])==0){
        item.sale_price = Number(e) * Number(item["104"]);
        console.log("log-1",item.sale_price);
      }
      console.log("sunlesx",item['185']);
      console.log('log-4',item.sale_price);
      item.sale_price = numeral(item.sale_price).format("0.00");
      if (item.discount_type === 20) {
        // 重新计算销售价
        if (typeof item["185"]!=="undefined" && item["185"] === "按克") {
          item.actual_sale_price = (Number(e) +sale_cost_rmb) * Number(item["104"]);
          item.actual_sale_price_2 = (Number(e) + sale_cost_rmb) * Number(item["104"]);
        }
        if (typeof item["185"]!=="undefined" && item["185"] === "按件") {
          item.actual_sale_price = Number(e) * Number(item["104"]) + sale_cost_rmb;
          item.actual_sale_price_2 = Number(e) * Number(item["104"]) + sale_cost_rmb;
        }
        
        if (item["185"] == "" && Number(item["184"]) == 0) {
          item.actual_sale_price = Number(e) * Number(item["104"]);
          item.actual_sale_price_2 = Number(e) * Number(item["104"]);
        }
        
        if(typeof item["185"]=="undefined" || typeof item["184"]=="undefined" || Number(item["184"])==0) {
          item.actual_sale_price = Number(e) * Number(item["104"]);
          item.actual_sale_price_2 = Number(e) * Number(item["104"]);
        }
        item.actual_sale_price = numeral(item.actual_sale_price).format("0.00");
        item.actual_sale_price_2 = numeral(item.actual_sale_price).format("0.00");
        // 计算折扣
        item.discount = numeral((item.actual_sale_price / item.sale_price) * 10).format("0.00");
      } else {
        item.actual_sale_price = numeral((item.sale_price * item.discount) / 10).format("0.00");
        item.actual_sale_price_2 = numeral((item.sale_price * item.discount) / 10).format("0.00");
      }
      item.sum_totalweight = numeral(item.num * item.totalweight).format("0.00");
      item.sum_sale_price = numeral(item.num * item.sale_price).format("0.00");
      item.sum_actual_sale_price = numeral(item.num * item.actual_sale_price).format("0.00");
      this.roundTag = false;
      this.changeSaving = false;
    },

    handleGongFei(e, index) {
      this.roundTag = false;
      const item = this.newArrival[index];
      if (typeof item["185"]!=="undefined"&&item["185"] === "按克") {
        item.sale_price = (Number(e) + Number(item.gold_price)) * Number(item["104"]);
      }
      if (typeof item["185"]!=="undefined"&&  item["185"] === "按件") {
        item.sale_price = Number(item.gold_price) * Number(item["104"]) + Number(e);
      }
      if(typeof item["185"]=="undefined" || typeof item["184"]=="undefined" || Number(item["184"])==0){
        item.sale_price=Number(item.gold_price) * Number(item["104"])
      }
      item.sale_price = numeral(item.sale_price).format("0.00");
      // 重新计算销售价
      item.actual_sale_price = (Number(item.sale_price) * Number(item.discount)) / 10;
      item.actual_sale_price = numeral(item.actual_sale_price).format("0.00");
    },

    // 此处商家id是变化的  需要根据接口动态设置
    getOldRowList() {
      getAttrListReq({
        hierarchy_id: storage.get("userInfo").hierarchy_id
      }).then(res => {
        if (res.code === 1) {
          this.oldRowList.forEach(old => {
            res.data.forEach(data => {
              if (old.field_alias == data.system_attr_id) {
                old.field_alias = "" + data.attr_id;
              }
            });
          });
        }
      });
    },
    setOldRowList(list) {
      const arr = [];
      list.forEach(item => {
        const obj = {
          field_text: item.attr_name,
          field_alias: item.attr_fields
        };
        arr.push(obj);
      });
      this.oldRowList = arr;
    },

    getDetail(detail) {
      this.discount["40"] = 0;
      this.card = [];
      detail.discount[40] = 0;
      if (detail.goodsList.newArrival) {
        detail.goodsList.newArrival.forEach(good => {
          getGoodsNumberStockReq({
            goods_number: good.goods_number,
            order_id: this.order_id
          }).then(result => {
            if (result.code === 1) {
              if (result.data.length) {
                // eslint-disable-next-line no-param-reassign
                result.data = result.data.map(item => {
                  return {
                    ...item,
                    warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                  };
                });
                good = {
                  ...good,
                  stockList: result.data,
                  num: good.pcs,
                  changeNum: true
                };
              } else {
                good = {
                  ...good,
                  stockList: [],
                  num: good.pcs,
                  changeNum: true
                };
              }
              this.newArrival.push(good);
            }
          });
        });
      }
      if (detail.goodsList.gift) {
        detail.goodsList.gift.forEach(good => {
          getGoodsNumberStockReq({
            goods_number: good.goods_number,
            order_id: this.order_id
          }).then(result => {
            if (result.code === 1) {
              if (result.data.length) {
                result.data = result.data.map(item => {
                  return {
                    ...item,
                    warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                  };
                });
                good = {...good, stockList: result.data};
              } else {
                good = {...good, stockList: []};
              }
              this.gift.push(good);
            }
          });
        });
      }

      if (detail.orderInfo.client_user_id) {
        this.getMemberInfo({client_user_id: detail.orderInfo.client_user_id}, () => {
          if (this.memberInfo.integral >= Number(this.orderInfo.use_inegral)) {
            this.orderInfo.use_money = detail.discount["50"];
            this.orderInfo.use_inegral = detail.orderInfo.use_inegral;
            this.integralInfo = detail.discount["50"];
          } else {
            this.$message.warning("当前会员积分不足!");
          }
          // 如果是挂单，回填优惠券
          if (detail.orderInfo.coupon_log_ids.length > 0) {
            // this.card = detail.orderInfo.coupon_log_ids;
            this.getDiscountList(() => {
              // const arr = detail.orderInfo.available.filter(
              //   item => detail.orderInfo.coupon_log_ids.findIndex(sitem => item.coupon_log_id == sitem.coupon_log_id)
              // );
              // if (!arr.length) {
              //   this.$message.warning("该优惠券已不存在!");
              //   return;
              // }
              // this.card = arr;
              // const card = { ...this.card };
              // const discount = {...this.discount};
              useDiscountNewReq({
                client_user_id: this.memberInfo.client_user_id,
                coupon_log_ids: detail.orderInfo.coupon_log_ids.map(item => item.coupon_log_id),
                goods_list: JSON.stringify(this.newArrival)
              }).then(res => {
                if (res.code === 1) {
                  // 优惠金额列
                  this.newArrival = this.newArrival.map(item => {
                    const obj = {...item};
                    return obj;
                  });
                  // this.cardList = res.data;
                  // this.cardList.total = res.data.available.length + res.data.unavailable.length;
                  // discount['40'] = res.data.discountAmount;
                  let total_count = 0;


                  this.newArrival.forEach(item => {
                    if (item.sales_type == 20) {
                      total_count += item.actual_sale_price * item.num;
                    }
                  });
                  let ds_total = 0;
                  let newArrival_len = this.newArrival.length;
                  let for_index = 1;
                  this.newArrival.forEach(item => {
                    item.actual_sale_price_2 = item.actual_sale_price;
                    item.sum_actual_sale_price = numeral(Number(item.actual_sale_price)*item.num).format("0.00");
                    // if (item.sales_type == 20) {
                    //   let percentage = item.actual_sale_price / total_count;
                    //   let ds = Math.round(Number(discount['40']) * percentage);
                    //   if (ds_total + (ds * item.num) > Number(discount['40']) && Number(discount['40']) - ds_total > 0) {
                    //     ds = (Number(discount['40']) - ds_total) / item.num;
                    //     this.$set(item, 'card_discount', numeral(ds * item.num).format("0.00"));
                    //     item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - ds).format("0.00")
                    //     if (this.judgeDivisor((Number(discount['40']) - ds_total), item.num)) {
                    //       item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                    //     } else {
                    //       item.sum_actual_sale_price = numeral(Math.round(item.actual_sale_price_2 * item.num)).format("0.00");
                    //     }
                    //   } else if (Number(discount['40']) - ds_total > 0) {
                    //     if (for_index == newArrival_len) {
                    //       this.$set(item, 'card_discount', numeral(Number(discount['40']) - ds_total).format("0.00"));
                    //       ds = (Number(discount['40']) - ds_total) / item.num;
                    //       item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - ds).format("0.00")
                    //       if (this.judgeDivisor((Number(discount['40']) - ds_total), item.num)) {
                    //         item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                    //       } else {
                    //         item.sum_actual_sale_price = numeral(Math.round(item.actual_sale_price_2 * item.num)).format("0.00");
                    //       }
                    //     } else {
                    //       this.$set(item, 'card_discount', numeral(ds * item.num).format("0.00"));
                    //       item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - (Math.round(Number(discount['40']) * percentage))).format("0.00");
                    //       item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                    //     }
                    //   } else {
                    //     this.$set(item, 'card_discount', numeral(0).format("0.00"));
                    //     item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                    //   }
                    //   ds_total = Number(ds_total) + Number(ds * item.num);
                    // }
                    // for_index++;
                  })

                  // discount["40"] = res.data.discountAmount;
                  // this.discount = discount;
                }
              });
              // this.discount = discount;
            });
          }
          // 回填预存券
          if (detail.orderInfo.store_prestore_user_id) {
            this.getPreList(() => {
              const arr = this.preList.available.filter(
                item => item.store_prestore_user_id == detail.orderInfo.store_prestore_user_id
              );
              if (!arr.length) {
                this.$message.warning("该预存券已不存在!");
                return;
              }
              this.pre = arr[0];
              const pre = {...this.pre};
              const discount = {...this.discount};
              discount["30"] = pre.expand_price;
              this.discount = discount;
              const total = {...this.total};
              total.preMoney = pre.expand_price;
              this.total = total;
            });
          }
        });
      }
      detail.orderInfo.main_store_user_id &&
      (this.form.main_store_user_id = Number(detail.orderInfo.main_store_user_id));
      detail.orderInfo.vice_store_user_id &&
      (this.form.vice_store_user_id = Number(detail.orderInfo.vice_store_user_id));
      this.ratioList = detail.orderInfo.ratioList;
      this.discount = detail.discount;
      this.orderInfo = detail.orderInfo;
      this.raw = detail.goodsList.raw;
      // this.newArrival = detail.goodsList.newArrival;
      // this.gift = detail.goodsList.gift;
      this.form = {
        ...this.form,
        time: "1"
      };
    },

    handleHand() {
      this.$confirm("确定要进行挂单操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.mountOrder = true;
        this.handleSum();
      });
    },

    // 根据积分计算最多可抵扣多少金额
    getAmount() {
      let data = {
        integral: this.memberInfo.integral,
        paid_in_amount:
          Number(this.total.actualMoney) +
          Number(this.orderInfo.use_money || 0) +
          Number(this.total.clearMoney || 0),
        client_user_id: this.memberInfo.client_user_id
      };
      if (this.order_id) {
        data.original_order_id = this.order_id;
      }
      getAmountReq({
        ...data
      }).then(res => {
        if (res.code === 1) {
          this.amount = res.data;
        }
      });
    },

    handleClearMoney(e) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        const reg = /^\d+(\.\d+)?$/;
        if (!reg.test(e)) {
          this.$message.warning("请输入数字");
        }
        if (e > 100) {
          this.$message.warning("抹零金额最大值为100");
          this.clearMax = true;
        } else {
          this.clearMax = false;
        }
        // 计算实收金额
        this.total.actualMoney = numeral(
          Number(this.total.orderMoney) -
          Number(this.total.oldMoney) -
          Number(this.total.preMoney) -
          // - Number(this.total.couponMoney)
          Number(this.discount["40"]) -
          Number(this.integralInfo) -
          Number(this.total.clearMoney) -
          Number(this.total.youhuiMoney) -
          Number(this.total.memberMoney)
        ).format("0.00");
        if (this.useSaving) {
          this.total.actualMoney = numeral(
            this.total.actualMoney - (Number(this.storageMoneyInfo.use_money) || 0)
          ).format("0.00");
          this.total.actualMoney = numeral(this.total.actualMoney).format("0.00");
        }
        // this.payInfo['20'] = e;
      }, 500);
    },

    handleyouhuiMoney(e) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        const reg = /^\d+(?=\.{0,1}\d+$|$)/;
        if (!reg.test(e)) {
          this.$message.warning("请输入正数");
        }
        // if (e > 100) {
        //   this.$message.warning('抹零金额最大值为100');
        //   this.clearMax = true;
        // } else {
        //   this.clearMax = false;
        // }
        // 计算实收金额
        this.total.actualMoney = numeral(
          Number(this.total.orderMoney) -
          Number(this.total.oldMoney) -
          Number(this.total.preMoney) -
          // - Number(this.total.couponMoney)
          Number(this.discount["40"]) -
          Number(this.integralInfo) -
          Number(this.total.clearMoney) -
          Number(this.total.youhuiMoney) -
          Number(this.total.memberMoney)
        ).format("0.00");
        if (this.useSaving) {
          this.total.actualMoney = numeral(
            this.total.actualMoney - Number(this.storageMoneyInfo.use_money)
          ).format("0.00");
        }
        // this.payInfo['70'] = e;
      }, 500);
    },

    // 改变货品价格
    handleChangePriceNew(goods) {
      this.changePriceNew = true;
      this.roundTag = false;
      this.changeSaving = false;
      for (let i = 0; i < this.newArrival.length; i++) {
        const item = this.newArrival[i];
        goods.sum_actual_sale_price = numeral(goods.num * goods.actual_sale_price).format("0.00");
        if (item.goods_id === goods.goods_id) {
          // if (goods.card_discount) {
          //   goods.card_discount = '--';
          //   this.$message.warning("请重新选择优惠券");
          // }

          this.newArrival.splice(i, 1, goods);
          break;
        }
      }
      this.reselectCard();
    },

    handleClearCard() {
      this.card = [];
      this.clearCard = true;
      const discount = {...this.discount};
      discount["40"] = 0;
      this.discount = discount;
      this.clearCard = true;
      this.newArrival = this.newArrival.map(item => {
        const obj = {...item};
        obj.sum_actual_sale_price = numeral(
          Number(obj.sum_actual_sale_price) + Number(obj.card_discount)
        ).format("0.00");
        obj.card_discount = "--";
        return obj;
      });
    },

    handleClearPre() {
      this.pre = {};
      this.clearPre = true;
      const discount = {...this.discount};
      discount["30"] = 0;
      this.discount = discount;
      this.total.preMoney = 0;
    },

    // reselectCard
    reselectCard() {
      // 获取优惠券列表
      // 先清空选中的优惠券
      // 如果原来存在优惠券
      if (this.card.length > 0) {
        this.card = [];
        this.coupon_log_ids = [];
        this.getDiscountList(() => {
          this.card = [];
          this.coupon_log_ids = [];
          const discount = {...this.discount};
          discount["40"] = 0;
          this.discount = discount;
          this.clearCard = true;
          this.$message.warning("请重新选择优惠券");
          if (this.newArrival.length) {
            this.newArrival = this.newArrival.map(item => {
              return {
                ...item,
                sum_actual_sale_price: numeral(Number(item.actual_sale_price) * Number(item.num)).format("0.00"),
                actual_sale_price_2: numeral(Number(item.actual_sale_price)).format("0.00"),
                card_discount: "--"
              };
            });
          }
        });
      }
    },

    reselectPre() {
      // 先清空选中的预存券
      // 如果原来存在优惠券
      this.getPreList(() => {
        this.handleClearPre();
        this.$message.warning("请重新选择预存券");
      });
    },
    // 处理预存
    handleChoosePre(pre) {
      this.pre = pre;
      const total = {...this.total};
      const discount = {...this.discount};
      discount["30"] = pre.expand_price;
      this.discount = discount;
      total.preMoney = pre.expand_price;
      this.total = total;
    },

    //判断是否除不尽
    judgeDivisor(m, n) {//JS判断两个数是否能除尽
      var num = {};
      var i = 0;
      var x = parseInt(m / n);
      m = m % n;
      var result = "";
      while (m != 0 && !(m in num)) {
        num[m] = i++;
        result += parseInt(m * 10 / n);
        m = m * 10 % n;
      }
      return m == 0;
    },

    // 优惠券组件回调
    handleChooseCard(card) {
      this.card = this.cardList.available.filter(item => card.includes(item.coupon_log_id));

      let coupon_log_ids = this.card.map(item => item.coupon_log_id);

      let params = {
        client_user_id: this.memberInfo.client_user_id,
          coupon_log_ids,
          goods_list: JSON.stringify(this.newArrival)
      }
      if(this.is_first_num >1)  this.is_first = 20;
      if (this.order_id) {
        params.is_first = this.is_first;
        params.order_id = this.order_id;
      }

      useDiscountNewReq({...params}).then(res => {
        if (res.code == 1) {
          this.cardList = res.data;
          this.cardList.total = res.data.available.length + res.data.unavailable.length;
          const discount = {...this.discount};
          discount['40'] = res.data.discountAmount;

          let len = this.newArrival.filter(item => item.sales_type == 20).length;

          let total = 0;
          this.newArrival.forEach(item => {
            if (item.sales_type == 20) {
              total += item.actual_sale_price * item.num;
            }
          });
          let ds_total = 0;
          let newArrival_len = this.newArrival.length;
          let for_index = 1;
          this.newArrival.forEach(item => {
            if (item.sales_type == 20) {
              item.actual_sale_price_2 = item.actual_sale_price;
              let percentage = item.actual_sale_price / total;
              let ds = Math.round(Number(discount['40']) * percentage);
              if (ds_total + (ds * item.num) > Number(discount['40']) && Number(discount['40']) - ds_total > 0) {
                ds = (Number(discount['40']) - ds_total) / item.num;
                this.$set(item, 'card_discount', numeral(ds * item.num).format("0.00"));
                item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - ds).format("0.00")
                if(this.judgeDivisor((Number(discount['40']) - ds_total),item.num)){
                  item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                }else{
                  item.sum_actual_sale_price = numeral(Math.round(item.actual_sale_price_2 * item.num)).format("0.00");
                }
              } else if (Number(discount['40']) - ds_total > 0) {
                if(for_index == newArrival_len){
                  this.$set(item, 'card_discount', numeral(Number(discount['40']) - ds_total).format("0.00"));
                  ds = (Number(discount['40']) - ds_total) / item.num;
                  item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - ds).format("0.00")
                  if(this.judgeDivisor((Number(discount['40']) - ds_total),item.num)){
                    item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                  }else{
                    item.sum_actual_sale_price = numeral(Math.round(item.actual_sale_price_2 * item.num)).format("0.00");
                  }
                }else{
                  this.$set(item, 'card_discount', numeral(ds * item.num).format("0.00"));
                  item.actual_sale_price_2 = numeral(item.actual_sale_price_2 - (Math.round(Number(discount['40']) * percentage))).format("0.00");
                  item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
                }
              }else {
                this.$set(item, 'card_discount', numeral(0).format("0.00"));
                item.sum_actual_sale_price = numeral(item.actual_sale_price_2 * item.num).format("0.00");
              }
              ds_total = Number(ds_total) + Number(ds * item.num);
            }
            for_index++;
          });


          // for(var i =0; i<this.newArrival.length,i++;){
          //   if(this.newArrival[i].sales_type == 20){
          //      let percentage = this.newArrival[i].actual_sale_price / total;
          //
          //     this.$set(this.newArrival[i],'card_discount',Math.round(numeral(Number(discount['40']) * percentage))*this.newArrival[i].num);
          //     console.log(this.newArrival[i].card_discount);
          //     discount_total += this.newArrival[i].card_discount;
          //   }
          // }

          // this.newArrival.forEach(item => {
          //   // alert(item.actual_sale_price_2);
          //   if(item.sales_type==20){
          //       // let percentage = item.actual_sale_price
          //
          //
          //
          //     this.$set(item, 'card_discount', numeral(Number(discount['40']) / len ).format("0.00"));
          //     // item.sum_actual_sale_price_2 =numeral(parseInt(item.sum_actual_sale_price) - (Number(discount['40']) / len)).format("0.00");
          //   }
          // })
          this.discount = discount;

        }
      })
      this.is_first_num++;
      // card_discount
      // // 类型 10：满减 20：满折 30：随机红包)
      // if (card.card_type === 10 || card.card_type === 30) {
      //   discount["40"] = card.worth;
      // }
      // if (card.card_type === 20) {
      //   discount["40"] = numeral(
      //     this.total.orderMoney * Number(1 - Number(card.worth) / 10)
      //   ).format("0.00");
      // }
      // useDiscountReq({
      //   client_card_voucher_id: card.client_card_voucher_id,
      //   goods_list: JSON.stringify(this.newArrival)
      // }).then(res => {
      //   if (res.code === 1) {
      //     // 优惠金额列
      //     this.newArrival = this.newArrival.map(item => {
      //       const obj = { ...item };
      //       obj.card_discount = res.data.list[item.goods_number].card_discount;
      //       // console.log(obj.sum_actual_sale_price, obj.card_discount);
      //       obj.sum_actual_sale_price = numeral(
      //         Number(obj.sum_actual_sale_price) - Number(obj.card_discount)
      //       ).format("0.00");
      //       return obj;
      //     });
      //     discount["40"] = res.data.total;
      //   }
      // });

      // this.discount = discount;
      // 需要把销售价合计重新计算，减掉优惠金额
    },
    // 获取预存列表
    getPreList(callback) {
      getPreListReq(this.memberInfo.client_user_id, this.total.actualMoney).then(res => {
        if (res.code === 1) {
          res.data.available.forEach(item => {
            item.decimal = item.expand_price.split(".")[1];
            item.certificate = item.expand_price.split(".")[0];
          });
          res.data.unavailable.forEach(item => {
            item.decimal = item.expand_price.split(".")[1];
            item.certificate = item.expand_price.split(".")[0];
          });
          this.preList = res.data;
        }
        if (callback) {
          callback();
        }
      });
    },

    // 优惠券列表
    getDiscountList(callback) {
      if(this.is_first_num >1)  this.is_first = 20;
      const params = {
        client_user_id: this.memberInfo.client_user_id,
        // order_amount: Number(this.total.orderMoney),
        coupon_log_ids: this.card.map(item => item.coupon_log_id),
        goods_list: JSON.stringify(this.newArrival)
        // discount_paid_amount: Number(this.total.actualMoney),
        // 跟实收无关
      };
      if (this.order_id) {
        params.is_first = this.is_first;
        params.order_id = this.order_id;
      }
      // const params = {
      //   client_user_id: 86,
      //   order_amount: 29999,
      //   discount_paid_amount: 19999,
      // };
      getDiscountListReq({...params}).then(res => {
        if (res.code === 1) {
          // res.data.available.forEach(item => {
          //   item.decimal = ("" + item.worth).split(".")[1];
          //   item.certificate = ("" + item.worth).split(".")[0];
          // });
          // res.data.unavailable.forEach(item => {
          //   item.decimal = ("" + item.worth).split(".")[1];
          //   item.certificate = ("" + item.worth).split(".")[0];
          // });

          this.cardList = res.data;
          this.cardList.total = res.data.available.length + res.data.unavailable.length;
          this.discount['40'] = numeral(res.data.discountAmount).format("0.00");

          // 如果是挂单，回填优惠券
          if (this.order_number && this.card.length != 0) {
            // const arr = this.cardList.available.filter(
            //   item => this.mountOrderInfo.coupon_log_ids.log_id.includes(item.coupon_log_id)
            // );
            // if (!arr.length) {
            //   return;
            // }
            //
            // this.card = arr;

            const card = [...this.card];
            const discount = {...this.discount};
            //这里需要请求接口，反填优惠券金额
            ///////////////////////////////////////////////////优惠券已废除///////////////////////////////////////////////////
            // useDiscountReq({
            //   client_card_voucher_id: card,
            //   goods_list: JSON.stringify(this.newArrival)
            // }).then(res => {
            //   if (res.code === 1) {
            //     // 优惠金额列
            //     this.newArrival = this.newArrival.map(item => {
            //       const obj = {...item};
            //       obj.card_discount = res.data.list[item.goods_number].card_discount;
            //       // console.log(obj.sum_actual_sale_price, obj.card_discount);
            //       // obj.sum_actual_sale_price = numeral(Number(obj.sum_actual_sale_price) - Number(obj.card_discount)).format('0.00');
            //       return obj;
            //     });
            //     discount["40"] = res.data.total;
            //     alert("data.total"+res.data.total)
            //     this.discount = discount;
            //   }
            // });
            ///////////////////////////////////////////////////优惠券已废除///////////////////////////////////////////////////
            // this.discount = discount;

            // 类型 10：满减 20：满折 30：随机红包)
            // if (card.card_type === 10 || card.card_type === 30) {
            //   discount["40"] = card.worth;
            // }
            // if (card.card_type === 20) {
            //   discount["40"] = numeral(
            //     this.total.orderMoney * Number(1 - Number(card.worth))
            //   ).format("0.00");
            // }
            // this.discount = discount;
          }
          this.is_first_num++;
          if (callback) {
            callback();
          }
        }
      });
    },

    // 添加卡券
    handleCard() {
      if (!this.memberInfo.client_user_id) {
        this.$message.warning("请先选择会员");
        return;
      }
      // if (!this.total.orderMoney) {
      //   this.$message.warning('订单金额为0不可选择优惠券');
      //   return;
      // }
      if (this.pre.store_prestore_user_id) {
        this.$message.warning("不能同时使用优惠券和预存");
        return;
      }
      this.cardVisible = true;
    },

    // 添加预存
    handlePre() {
      if (!this.memberInfo.client_user_id) {
        this.$message.warning("请先选择会员");
        return;
      }
      // if (!this.total.orderMoney) {
      //   this.$message.warning('订单金额为0不可添加预存');
      //   return;
      // }
      if (this.card.client_card_voucher_id) {
        this.$message.warning("不能同时使用优惠券和预存");
        return;
      }
      this.preVisible = true;
    },

    getMemberInfo({client_user_id, original_order_id}, callback) {
      getInfoReq({client_user_id, original_order_id}).then(res => {
        if (res.code === 1) {
          this.memberInfo = res.data.user_info;
          callback();
        }
      });
    },

    // 提单
    getMountOrder() {
      this.mountLoading = true;
      getMountOrderReq(this.order_number)
        .then(res => {
          if (res.code === 1) {
            this.mountLoading = false;
            const {data} = res;
            this.mountOrderInfo = data;
            // 主销售和负销售
            this.form = {
              ...this.form,
              main_store_user_id: data.orderInfo.main_store_user_id,
              vice_store_user_id: data.orderInfo.vice_store_user_id
            };
            // 销售比例
            this.ratioList = data.orderInfo.ratioList;
            this.newArrival = data.goodsList.newArrival;
            this.gift = data.goodsList.gift;
            this.raw = data.goodsList.raw;
            this.orderInfo = {
              use_inegral: data.orderInfo.use_inegral
            };
            this.handleTotal();
            // 根据会员id请求会员详情
            if (data.orderInfo.client_user_id) {
              this.getMemberInfo({client_user_id: data.orderInfo.client_user_id}, () => {
                // 积分换算金额
                this.getAmountByIntegral(data.orderInfo.use_money);
              });
            }
          }
        })
        .catch(() => {
          this.mountLoading = false;
        });
    },

    // 处理改价
    handleChangePrice(row) {
      // if (!this.memberInfo.client_user_id) {
      //   this.$message.warning('请先选择会员');
      //   return;
      // }
      if (this.card.client_card_voucher_id) {
        this.$message.warning("请先删除优惠券再进行改价");
        return;
      }
      this.changeVisible = true;
      this.currRow = row;
    },

    // 获取下单货品会员折扣
    getCustomerDiscount({client_user_id, goods_id}, callback) {
      getCustomerDiscountReq({client_user_id, goods_id}).then(res => {
        if (res.code === 1) {
          callback(res.data, goods_id);
        }
      });
    },
    // 监听积分输入
    handleInput(e) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        if (e) {
          this.getAmountByIntegral(e);
        } else {
          this.integralInfo = 0;
        }
      }, 500);
    },
    // 根据积分换算金额
    getAmountByIntegral(integral) {
      if (!this.total.orderMoney) {
        this.$message.warning("订单金额为0不能使用积分抵扣金额");
        return;
      }
      if(this.mountOrderInfo.is_check_integral==1&&!this.memberInfo.integral){
        //  if (!this.memberInfo.integral) {
            this.$message.warning("没有可用的积分");
            return;
          // }
      }
      if (integral > this.amount.order_lower_amount) {
        this.$message.warning(`最多可抵扣${this.amount.order_lower_amount}元, 请重新输入。`);
        return;
      }
      this.integralInfo = integral;
      this.loading2 = true;
      let data = {
        client_user_id: this.memberInfo.client_user_id,
        paid_in_amount: this.orderInfo.use_money // 这里有问题
      };
      if (this.order_id) {
        data.original_order_id = this.order_id;
      }
      getIntegralByAmountReq({
        ...data
      })
        .then(res => {
          if (res.code === 1) {
            this.loading2 = false;
            this.orderInfo.use_inegral = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
      // getAmountByIntegralReq(integral)
      //   .then((res) => {
      //     if (res.code === 1) {
      //       this.loading2 = false;
      //       this.integralInfo = numeral(res.data).format('0.00');
      //       if (Number(this.integralInfo) > Number(this.total.orderMoney)) {
      //         // 积分抵扣的金额超过了订单金额；错误
      //         this.scaleOut = true;
      //       } else {
      //         this.scaleOut = false;
      //       }
      //     }
      //   })
      //   .catch(() => {
      //     this.loading2 = false;
      //   });
    },
    // 确定结算(挂单)
    handleSum() {
      if (this.canRepairOrder) {
        if (this.form.time == "2" && !this.create_time) {
          this.$message.warning("请选择自定义时间");
          return;
        }
      }
      // 如果非挂单
      if (!this.mountOrder) {
        if (Number(this.surplusMoney) !== 0) {
          this.$message.warning("剩余应还应为￥0");
          return;
        }
      }
      // 请求接口
      // 组装后端需要接口数据
      const newArrival2 = [];
      this.newArrival.forEach(item => {
        const obj = {
          goods_id: item.goods_id,
          goods_number: item.goods_number,
          goods_name: item.goods_name,
          goods_type_name: item.goods_type_name,
          goods_number_type: item.goods_number_type,
          num: item.num,
          sale_price: item.sale_price,
          discount: item.discount,
          actual_sale_price: item.actual_sale_price,
          pcs: item.num,
          gold_price: item.gold_price,
          sale_cost: item["184"],
          discount_type: this.originRadio === 1 ? 10 : 20,
          goods_type_id: item.goods_type_id,
          sale_warehouse: item.sale_warehouse,
          totalweight: item.totalweight,
          sum_totalweight: item.sum_totalweight,
          sum_sale_price: item.sum_sale_price,
          sum_actual_sale_price: item.sum_actual_sale_price,
          exchange_price: item.exchange_price,
          sales_type: item.sales_type
          // ...item,
        };
        // 开启了储值金
        if (this.useSaving) {
          obj.storage_money = item.storage_money;
        }

        newArrival2.push(obj);
      });
      const gift2 = [];
      this.gift.forEach(item => {
        const obj = {
          goods_id: item.goods_id,
          goods_number: item.goods_number,
          goods_name: item.goods_name,
          goods_type_name: item.goods_type_name,
          goods_number_type: item.goods_number_type,
          104: item["104"],
          pcs: item.num || item.pcs,
          num:item.num,
          sale_price: item.sale_price,
          actual_sale_price: item.actual_sale_price,
          sale_warehouse: item.sale_warehouse
          //  ...item,
        };
        gift2.push(obj);
      });
      // 组装后端需要的商品信息
      const goodsList = {
        gift: gift2,
        raw: this.raw,
        newArrival: newArrival2
      };

      // 组装打折信息
      const discount = {
        // '20': Number(this.total.clearMoney) + Number(this.discount['20']),
        20: Number(this.total.clearMoney),
        30: this.total.preMoney,
        40: Number(this.discount["40"]),
        50: this.integralInfo,
        60: this.total.memberMoney,
        70: this.total.youhuiMoney
      };

      // 开启了储值金
      if (this.useSaving) {
        discount["80"] = this.storageMoneyInfo.use_money;
      }

      // 组装订单信息
      const orderInfo = {
        pay_way: 10,
        remark: this.orderInfo.remark,
        client_user_id: this.memberInfo.client_user_id,
        main_store_user_id: this.form.main_store_user_id,
        vice_store_user_id: this.form.vice_store_user_id,
        channel_type: 10,
        use_inegral: this.orderInfo.use_inegral || 0
        // order_number: this.order_number,
      };

      // 如果是提单
      if (this.order_number) {
        orderInfo.order_number = this.order_number;
      }

      // 如果是回收单反确认
      if (this.recycle_order_id) {
        // 如果order_id存在 表示是旧料和新品的订单
        if (this.mountOrderInfo.orderInfo.order_id) {
          orderInfo.original_order_id = this.mountOrderInfo.orderInfo.order_id;
        } else {
          orderInfo.original_recycle_id = this.recycle_order_id;
        }
      }

      // 如果使用了优惠券
      if (this.card.length > 0) {
        // orderInfo.source_id = this.card.source_id;(要打开）
        // orderInfo.client_card_voucher_id = this.card.client_card_voucher_id;
        // orderInfo.tools_type = this.card.tools_type;
        //orderInfo.coupon_log_ids = this.cardList.available.filter(item => this.card.findIndex(sitem => sitem.coupon_log_id == item.coupon_log_id));
        let obj;
        orderInfo.coupon_log_ids=[];
        this.card.forEach(item =>{
          obj=this.cardList.available.filter(sitem => {
             return sitem.coupon_log_id == item.coupon_log_id;
           })
          orderInfo.coupon_log_ids.push(Number(obj[0].coupon_log_id))
        })


      }

      if (this.mountOrder) {
        orderInfo.unavailable = this.cardList.unavailable;
        orderInfo.available = this.cardList.available;
        orderInfo.coupon_log_ids = this.cardList.available.filter(item => this.card.findIndex(sitem => sitem.coupon_log_id == item.coupon_log_id) != -1);
      }

      // 如果用了自定义时间
      if (this.canRepairOrder) {
        if (this.form.time == "2" && this.create_time) {
          orderInfo.create_time = this.create_time;
        }
      }

      // 如果使用了预存
      if (this.pre.store_prestore_user_id) {
        orderInfo.store_prestore_user_id = this.pre.store_prestore_user_id;
      }
      // 组装收款方式
      let payInfo = this.payInfo;
      // payInfo = {
      //   10: this.payInfo["10"] || 0,
      //   20: this.payInfo["20"] || 0,
      //   30: this.payInfo["30"] || 0,
      //   40: this.payInfo["40"] || 0,
      //   50: this.payInfo["50"] || 0,
      //   70: this.payInfo["70"] || 0,
      // };
      let total = 0;
      newArrival2.forEach(item => {
        total += Number(item.num);
      });

      gift2.forEach(item => {
        total += Number(item.pcs);
      });
      total += Number(this.raw.length);

      // 如果销售比例只有一个，百分之百
      if (this.ratioList.length === 1) {
        this.ratioList[0].ratio = "100.00";
      }
      // 结算
      if (this.mountOrder) {
        this.handleMountOrder({
          goodsList,
          discount,
          orderInfo,
          ratioList: this.ratioList,
          total_money: this.total.actualMoney,
          total_num: total
        });
        return;
      }
      this.loading = true;
      if (!this.reverse) {
        createOrderReq({
          goodsList,
          discount,
          orderInfo,
          ratioList: this.ratioList,
          payInfo
          // original_order_id: this.reverse ? this.order_id : '',
        })
          .then(res => {
            if (res.code === 1) {
              // 请求订单详情
              if (res.data.order_id) {
                this.printType = 20;
                getOrderInfoReq(res.data.order_id)
                  .then(result => {
                    if (result.code === 1) {
                      this.loading = false;
                      this.printData = result.data;
                      this.printData1 = result.data.goods.filter(item => {
                        return !item.isRaw;
                      });
                      this.printData2 = result.data.goods.filter(item => {
                        return item.isRaw;
                      });
                      this.getSums();
                      this.getSums2();
                      // 打印处理结束
                      this.settlementVisible = false;
                      this.currRow = {};
                      this.currRow.order_id = res.data.order_id;
                      this.successVisible = true;
                      this.successInfo = res.data;
                      this.successInfo.money = numeral(
                        Number(this.total.actualMoney) - Number(this.discount["20"])
                      ).format("0.00");
                      this.successInfo.payText =
                        (this.payInfo["10"] ? "现金" : "") +
                        " " +
                        (this.payInfo["20"] ? "微信支付 " : "") +
                        (this.payInfo["30"] ? "支付宝" : "") +
                        (this.payInfo["40"] ? "银行转账" : "") +
                        (this.payInfo["50"] ? "其他" : "");
                    }
                  })
                  .catch(() => {
                    this.infoLoading = false;
                    this.loading = false;
                  });
              } else {
                this.printType = 40;
                getRecycleOrderData(res.data.recycle_id)
                  .then(result => {
                    if (result.code === 1) {
                      this.loading = false;
                      this.printData = result.data;
                      this.printData1 = result.data.goods.filter(item => {
                        return !item.isRaw;
                      });
                      this.printData2 = result.data.goods.filter(item => {
                        return item.isRaw;
                      });
                      this.getSums();
                      this.getSums2();
                      // 打印处理结束
                      this.settlementVisible = false;
                      this.currRow = {};
                      this.currRow.recycle_id = res.data.recycle_id;
                      this.successVisible = true;
                      this.successInfo = res.data;
                      this.successInfo.money = numeral(
                        Number(this.total.actualMoney) - Number(this.discount["20"])
                      ).format("0.00");
                      this.successInfo.payText =
                        (this.payInfo["10"] ? "现金" : "") +
                        " " +
                        (this.payInfo["20"] ? "微信支付 " : "") +
                        (this.payInfo["30"] ? "支付宝" : "") +
                        (this.payInfo["40"] ? "银行转账" : "") +
                        (this.payInfo["50"] ? "其他" : "");
                    }
                  })
                  .catch(() => {
                    this.infoLoading = false;
                    this.loading = false;
                  });
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        resetOrderReq({
          goodsList,
          discount,
          orderInfo,
          payInfo,
          ratioList: this.ratioList,
          original_order_id: this.reverse
            ? this.order_id || this.mountOrderInfo.orderInfo.order_id
            : ""
        })
          .then(res => {
            if (res.code === 1) {
              // 请求订单详情
              if (res.data.order_id) {
                this.printType = 20;
                getOrderInfoReq(res.data.order_id)
                  .then(result => {
                    if (result.code === 1) {
                      this.loading = false;
                      this.printData = result.data;
                      this.printData1 = result.data.goods.filter(item => {
                        return !item.isRaw;
                      });
                      this.printData2 = result.data.goods.filter(item => {
                        return item.isRaw;
                      });
                      this.getSums();
                      this.getSums2();
                      // 打印处理结束
                      this.settlementVisible = false;
                      this.currRow = {};
                      this.currRow.order_id = res.data.order_id;
                      this.successVisible = true;
                      this.successInfo = res.data;
                      this.successInfo.money = numeral(
                        Number(this.total.actualMoney) - Number(this.discount["20"])
                      ).format("0.00");
                      this.successInfo.payText =
                        (this.payInfo["10"] ? "现金" : "") +
                        " " +
                        (this.payInfo["20"] ? "微信支付 " : "") +
                        (this.payInfo["30"] ? "支付宝" : "") +
                        (this.payInfo["40"] ? "银行转账" : "") +
                        (this.payInfo["50"] ? "其他" : "");
                    }
                  })
                  .catch(() => {
                    this.infoLoading = false;
                    this.loading = false;
                  });
              } else {
                this.printType = 40;
                getRecycleOrderData(res.data.recycle_id)
                  .then(result => {
                    if (result.code === 1) {
                      this.loading = false;
                      this.printData = result.data;
                      this.printData1 = result.data.goods.filter(item => {
                        return !item.isRaw;
                      });
                      this.printData2 = result.data.goods.filter(item => {
                        return item.isRaw;
                      });
                      this.getSums();
                      this.getSums2();
                      // 打印处理结束
                      this.settlementVisible = false;
                      this.currRow = {};
                      this.currRow.recycle_id = res.data.recycle_id;
                      this.successVisible = true;
                      this.successInfo = res.data;
                      this.successInfo.money = numeral(
                        Number(this.total.actualMoney) - Number(this.discount["20"])
                      ).format("0.00");
                      this.successInfo.payText =
                        (this.payInfo["10"] ? "现金" : "") +
                        " " +
                        (this.payInfo["20"] ? "微信支付 " : "") +
                        (this.payInfo["30"] ? "支付宝" : "") +
                        (this.payInfo["40"] ? "银行转账" : "") +
                        (this.payInfo["50"] ? "其他" : "");
                    }
                  })
                  .catch(() => {
                    discount
                    this.infoLoading = false;
                    this.loading = false;
                  });
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 挂单
    handleMountOrder({goodsList, discount, orderInfo, total_money, total_num}) {
      mountOrderReq({
        goodsList,
        discount,
        orderInfo,
        total_money,
        total_num,
        ratioList: this.ratioList
      })
        .then(res => {
          if (res.code === 1) {
            this.$message.success("挂单成功");
            this.mountOrder = false;
            this.memberInfo = {};
            this.ratioList = [{id: 1, ratio: "100.00", store_user_id: ""}];
            this.form = {};
            this.handleAllClear();
            this.card = [];
            this.cardList = {
              available: [],
              total: 0,
              unavailable: []
            };
            this.pre = {};
            this.preList = {
              available: [],
              total: 0,
              unavailable: []
            };
            this.payInfo = {};
            this.discount = {
              20: 0,
              40: 0
            };
            this.integralInfo = 0;
            this.orderInfo = {};
            this.total = {
              orderMoney: 0, // 订单金额
              oldMoney: 0, // 旧料金额
              preMoney: 0, // 预存金额
              couponMoney: 0, // 优惠券折扣金额
              integralMoney: 0, // 积分抵扣金额
              clearMoney: 0, // 抹零金额
              memberMoney: 0, // 会员折扣金额
              actualMoney: 0 // 实收金额
            };
          }
        })
        .catch(() => {
          this.mountOrder = false;
        });
    },
    // 计算剩余价
    handleSurplus() {
      let total = 0;
      for (let key in this.payInfo) {
        total += Number(this.payInfo[key]);
      }
      this.surplusMoney = numeral(Number(this.total.actualMoney) - Number(total)).format("0.00");
      // if (this.discount['20']) {
      //   this.surplusMoney = numeral(Number(this.surplusMoney) - Number(this.discount['20'])).format('0.00');
      // }
    },
    // 结算
    hanldeSettlement() {
      if (!this.newArrival.length && !this.raw.length && !this.gift.length) {
        this.$message.warning("请筛选货品");
        return;
      }
      // 如果有旧料和有新品，旧料必须绑定新品
      let errFlag;
      if (this.newArrival.length && this.raw.length) {
        for (let i = 0; i < this.raw.length; i += 1) {
          const item = this.raw[i];
          console.log(item,'旧料关联')
          if (!item.exchange_goods_number) {
            errFlag = true;
            break;
          }
        }
      }
      if (errFlag) {
        this.$message.warning("旧料必须关联商品");
        return;
      }
      if (this.scaleOut) {
        this.$message.warning("积分抵扣金额超过订单金额，请重新输入积分");
        return;
      }
      if (this.clearMax) {
        this.$message.warning("抹零金额不能超过100");
        return;
      }
      // 如果销售信息有员工重复
      let obj = {};
      for (let i = 0; i < this.ratioList.length; i += 1) {
        const item = this.ratioList[i];
        if (!item.store_user_id) {
          errFlag = true;
          this.$message.warning("销售员不得为空");
          break;
        }
        if (!obj[item.store_user_id]) {
          obj[item.store_user_id] = 1;
        } else {
          errFlag = true;
          this.$message.warning("销售员不得重复");
          break;
        }
      }
      if (errFlag) {
        return;
      }
      this.settlementVisible = true;
    },
    // 回车
    onSubmit(e, value) {
      if (e.keyCode === 13) {
        if (!value) {
          return;
        }
        this.goods_number = "";
        goodsReq({
          goods_number: value,
          allot_state: 1,
          query_type: "order_goods"
        }).then(res => {
          if (res.code === 1) {
            if (!res.data.total) {
              this.$message.warning("查询不到该货号");
              return;
            }
            for (let i = 0; i < res.data.list.length; i += 1) {
              const item = res.data.list[i];
              if (!item.is_set_price) {
                this.$message.error("未设置今日金价");
                return;
              }
            }
            // 以下状态不可选
            let breakFlg = false;
            if (res.data.list[0].goods_tag_id) {
              const goods_tag_id = res.data.list[0].goods_tag_id.split(",");
              // 不能选择的标志
              const tagList = ["3", "4", "5", "6", "7", "9", "10", "11", "12"];
              goods_tag_id.forEach(item => {
                tagList.forEach(tag => {
                  if (item === tag) {
                    // 防止一码多货，需要判断可操作为0
                    if (!res.data.list[0].usable_count) {
                      this.$message.warning(`该货品状态：${this.tagList[item]}，不可操作`);
                      breakFlg = true;
                    }
                  }
                });
              });
            }
            if (breakFlg) {
              return;
            }
            // 数据是否有重复
            getGoodsNumberStockReq({
              goods_number: res.data.list[0].goods_number,
              order_id: this.order_id
            }).then(result => {
              if (result.code === 1) {
                let m = 0;
                result.data = result.data.map(item => {
                  return {
                    ...item,
                    warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                  };
                });
                let item = {
                  ...res.data.list[0],
                  stockList: result.data,
                  sale_warehouse: result.data[0].warehouse_id
                };
                if (!this.newArrival.length) {
                  // const item = { ...res.data.list[0] };
                  item.num = 1;
                  item.pcs = 1;
                  item.originG = item["184"];
                  this.newArrival.push(item);
                  this.newArrival.forEach((item, index) => {

                    item.index = index;
                    if (this.ext_params) {
                      item.actual_sale_price_2 = numeral(item.actual_sale_price).format("0.00");
                      console.log("wbzx")
                      const arr = ("" + item.sale_price).split(".");
                      if (arr.length === 2) {
                        if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
                          item.sale_price = Number(arr[0]) + 1 + ".00";
                          item.actual_sale_price = numeral(
                            (Number(item.sale_price) * item.discount) / 10
                          ).format("0.00");
                          item.actual_sale_price_2 = numeral(
                            (Number(item.sale_price) * item.discount) / 10
                          ).format("0.00");
                        } else {
                          item.sale_price = Number(arr[0]) + ".00";
                          item.actual_sale_price = numeral(
                            (Number(item.sale_price) * item.discount) / 10
                          ).format("0.00");
                          item.actual_sale_price_2 = numeral(
                            (Number(item.sale_price) * item.discount) / 10
                          ).format("0.00");
                        }
                      }
                    }
                    this.settingList.forEach(setting => {
                      if (item.goods_type_id === setting.goods_type_id) {
                        item.storage_money_discount = setting.storage_money_discount || 0;
                      }
                    });
                  });
                  return;
                }
                this.newArrival.forEach(newItem => {
                  if (newItem.goods_id !== res.data.list[0].goods_id) {
                    m += 1;
                    if (m === this.newArrival.length) {
                      // const item = { ...res.data.list[0] };
                      item.num = 1;
                      item.pcs = 1;
                      item.originG = item["184"];
                      this.newArrival.push(item);
                      this.newArrival.forEach((item, index) => {
                        console.log("wbzxl2")
                        item.actual_sale_price_2 = numeral(item.actual_sale_price).format("0.00");
                        item.index = index;
                        if (this.ext_params) {
                          const arr = ("" + item.sale_price).split(".");
                          if (arr.length === 2) {
                            if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
                              item.sale_price = Number(arr[0]) + 1 + ".00";
                              item.actual_sale_price = numeral(
                                (Number(item.sale_price) * item.discount) / 10
                              ).format("0.00");
                              item.actual_sale_price_2 = numeral(
                                (Number(item.sale_price) * item.discount) / 10
                              ).format("0.00");
                            } else {
                              item.sale_price = Number(arr[0]) + ".00";
                              item.actual_sale_price = numeral(
                                (Number(item.sale_price) * item.discount) / 10
                              ).format("0.00");
                              item.actual_sale_price_2 = numeral(
                                (Number(item.sale_price) * item.discount) / 10
                              ).format("0.00");
                            }
                          }
                        }
                        this.settingList.forEach(setting => {
                          if (item.goods_type_id === setting.goods_type_id) {
                            item.storage_money_discount = setting.storage_money_discount || 0;
                          }
                        });
                      });
                    }
                  } else {
                    this.$message.warning("该数据已存在。");
                  }
                });
              }
            });
          }
        });
      }
    },
    handleAllClear() {
      this.raw = [];
      this.gift = [];
      this.newArrival = [];
    },
    // 算钱
    handleTotal() {
      // 计算订单金额
      let orderMoney = 0;
      let memberMoney = 0;
      this.newArrival.forEach(item => {
        if (!item.num) {
          item.num = item.pcs;
        }
        // orderMoney += Number(item.num * item.sale_price);
        orderMoney = Number(orderMoney) + Number(item.num) * Number(item.sale_price);
        orderMoney = numeral(orderMoney).format("0.00");
        // 计算会员折扣金额
        // memberMoney += Number(
        //   item.num * item.sale_price - item.num * item.actual_sale_price
        // );
        memberMoney =
          Number(memberMoney) +
          (Number(item.num) * Number(item.sale_price) -
            Number(item.num) * Number(item.actual_sale_price));
        memberMoney = numeral(memberMoney).format("0.00");
      });
      // this.total.orderMoney = numeral(orderMoney).format("0.00");
      this.total.orderMoney = orderMoney;
      // this.total.memberMoney = numeral(memberMoney).format("0.00");
      this.total.memberMoney = memberMoney;
      // 计算旧料金额
      let oldMoney = 0;
      this.raw.forEach(item => {
        oldMoney += Number(item.pcs * item.recycle_price);
      });

      this.total.oldMoney = numeral(oldMoney).format("0.00");
      // 计算实收金额
      const actualMoney = numeral(
        Number(this.total.orderMoney) -
        Number(this.total.oldMoney) -
        Number(this.total.preMoney) -
        // - Number(this.total.couponMoney)
        Number(this.discount["40"]) -
        Number(this.integralInfo) -
        Number(this.total.youhuiMoney) -
        // - Number(this.total.clearMoney)
        Number(this.total.memberMoney)
      ).format("0.00");
      // 抹零金额
      if (Number(this.total.clearMoney)) {
        this.total.clearMoney = numeral(this.total.clearMoney).format("0.00");
      } else {
        if (this.ml_set === 2) {
          if (this.newArrival.length) {
            // const money = Number(actualMoney) + Number(this.total.oldMoney);
            const money = Number(actualMoney);
            this.total.clearMoney = numeral(Number(money) - Math.floor(money)).format("0.00");
          }
        }
      }
      if (!this.total.youhuiMoney) {
        this.total.youhuiMoney = 0;
      }
      // 计算实收金额
      this.total.actualMoney = numeral(Number(actualMoney) - this.total.clearMoney).format("0.00");

      if (this.useSaving) {
        this.total.actualMoney = numeral(
          this.total.actualMoney - Number(this.storageMoneyInfo.use_money)
        ).format("0.00");
      }
    },
    // 旧料移除
    handleMove(tag, row) {
      this[tag] = this[tag].filter(item => item !== row);
      this.handleExchangePrice();
    },
    // 处理旧料信息
    handleOld(olds) {
      // 此处后期可能需要去重
      if (olds.index || olds.index === 0) {
        this.raw.splice(olds.index, 1, olds);
        this.handleExchangePrice();
        return;
      }
      this.raw.push(olds);
      // 货品的回收金额合计
      this.handleExchangePrice();
      // this.newArrival = this.newArrival.map((item) => {
      //   let obj = { ...item };
      //   if (olds.exchange_goods_number === item.goods_number) {
      //     obj = {
      //       ...item,
      //       exchange_price: numeral(Number(item.exchange_price) + Number(olds.recycle_price)).format('0.00'),
      //     }
      //   }
      //   return obj;
      // })
    },
    // 计算货品的回收价
    handleExchangePrice() {
      this.newArrival.forEach(newItem => {
        newItem.exchange_price = 0;
        this.raw.forEach(item => {
          if (newItem.goods_number === item.exchange_goods_number) {
            newItem.exchange_price = numeral(
              Number(newItem.exchange_price) + Number(item.recycle_price)
            ).format("0.00");
          }
        });
      });
    },

    handleDele(tag, goods_id, goods_number) {
      this.roundTag = false;
      this.changeSaving = false;
      this.newArrival = this.newArrival.filter(item => item.goods_id != goods_id);

      this.raw.forEach(item => {
        if (item.exchange_goods_number === goods_number) {
          item.exchange_goods_number = "";
          item.exchange_goods_name = "";
        }
      });
      if (this.newArrival.length == 0) {
        this.discount['40'] = 0;
      }
    },

    // *************************
    handleGoodVisibel(tag) {
      // if (!this.memberInfo.client_user_id) {
      //   this.$message.warning('请先添加会员');
      //   return;
      // }
      if (tag === "gift") {
        this.giftVisibel = true;
      } else {
        this.goodVisibel = true;
      }
      this.tag = tag;
    },

    // 获取员工列表
    getEmployee() {
      getEmployeeReq().then(res => {
        if (res.code === 1) {
          this.employeeList = res.data;
        }
      });
    },

    handleAdd(memberInfo) {
      this.changePriceNew = false;
      this.roundTag = false;
      this.memberInfo = memberInfo;
    },

    handleHideChange() {
      this.changeVisible = false;
    },

    handleGua() {
      this.$confirm("挂单后可以在新增订单里重新提取, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.mountOrder = true;
          this.handleSum();
        })
        .catch(() => {
          this.mountOrder = false;
        });
    },

    handleSuccessClose() {
      this.successVisible = false;
      this.$emit("onAddHide", "getList");
    },

    // 关闭
    handleClose() {
      this.settlementVisible = false;
    },

    handleHideOld() {
      this.oldVisible = false;
    },

    handleGoods(goods) {
      // 如果是货品 需要判断有没有设置今日金价
      if (this.tag === "newArrival") {
        this.roundTag = false;
        this.changeSaving = false;
        for (let i = 0; i < goods.length; i += 1) {
          const item = goods[i];
          if (!item.is_set_price) {
            this.$message.error("未设置今日金价");
            return;
          }
        }
      }

      this.allot_goods = [...goods];
      // 去重
      let newArr = [];
      const obj = {};
      this.allot_goods.forEach(item => {
        const {goods_id} = item;
        if (!obj[goods_id]) {
          obj[goods_id] = true;
          const newItem = {...item, exchange_price: 0};
          if (!newItem.changeNum) {
            newItem.num = 1;
          }
          if (!newItem.originG) {
            newItem.originG = newItem["184"];
          }
          newItem.pcs = newItem.num;
          //
          // sum_actual_sale_price = numeral(goods.num * goods.actual_sale_price

          // 赋值一个前端展示的商品销售价格和商品合计价格
          //   item.copy_sum_actual_sale_price=item.sum_actual_sale_price;
          //   item.copy_actual_sale_price=item.actual_sale_price;
          newArr.push(newItem);
        }
      });
      // newArr.forEach((item, index) => {
      //   item.index = index;
      // });
      let m = 0;
      newArr.forEach(item => {
        // 请求仓库
        getGoodsNumberStockReq({
          goods_number: item.goods_number,
          order_id: this.order_id
        }).then(res => {
          if (res.code === 1) {
            m += 1;
            if (res.data.length) {
              res.data = res.data.map(item => {
                return {
                  ...item,
                  warehouse_name: `${item.warehouse_name}(${item.stock}件)`
                };
              });
              item = {
                ...item,
                stockList: res.data,
                sale_warehouse: res.data[0].warehouse_id
              };
            } else {
              item = {...item, stockList: []};
            }
            // 处理数据
            this[this.tag].push(item);
            if (m === newArr.length) {
              if (this.tag === "newArrival") {
                this.newArrival.forEach(item => {
                  item.actual_sale_price_2 = item.actual_sale_price;
                  this.settingList.forEach(setting => {
                    if (item.goods_type_id === setting.goods_type_id) {
                      item.storage_money_discount = setting.storage_money_discount || 0;
                    }
                  });
                });
              }
            }
          }
        });

        // 四舍五入
        if (this.ext_params) {
          const arr = ("" + item.sale_price).split(".");
          if (arr.length === 2) {
            if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
              item.sale_price = Number(arr[0]) + 1 + ".00";
              item.actual_sale_price = numeral(
                (Number(item.sale_price) * item.discount) / 10
              ).format("0.00");
            } else {
              item.sale_price = Number(arr[0]) + ".00";
              item.actual_sale_price = numeral(
                (Number(item.sale_price) * item.discount) / 10
              ).format("0.00");
            }
            item.sum_totalweight = numeral(item.num * item.totalweight).format("0.00");
            item.sum_sale_price = numeral(item.num * item.sale_price).format("0.00");
            item.sum_actual_sale_price = numeral(item.num * item.actual_sale_price).format("0.00");
          }
          // if (newItem.discount != 10) {
          //   const arr = newItem.actual_sale_price.split(".");
          //   if (arr.length === 2) {
          //     let actual_sale_price;
          //     if (Number(arr[1].slice(0, 1)) >= this.ext_params) {
          //       // 四舍五入进1
          //       // 小于标签价才能五入
          //       if (Number(arr[0]) + 1 < newItem.sale_price) {
          //         actual_sale_price = Number(arr[0]) + 1 + ".00";
          //       } else {
          //         actual_sale_price = arr[0] + "." + arr[1];
          //       }
          //     } else {
          //       actual_sale_price = Number(arr[0]) + ".00";
          //     }
          //     this.$set(this.newArrival, index, {
          //       ...newItem,
          //       actual_sale_price: actual_sale_price,
          //     });
          //   }
        }
      });

      // this[this.tag] = newArr;
      // if (this.tag === "newArrival") {
      //   this.newArrival.forEach((item) => {
      //     this.settingList.forEach((setting) => {
      //       if (item.goods_type_id === setting.goods_type_id) {
      //         item.storage_money_discount = setting.storage_money_discount || 0;
      //       }
      //     });
      //   });
      // }
    },

    handleChange() {
    },
    goBack() {
      this.$emit("onAddHide");
      this.mountOrder = false;
    },
    handleHideNumber() {
      this.numberVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.money {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}

.ratio {
  border: 1px solid #ddd;
  height: 40px;
  box-sizing: border-box;
  width: 60px;
  text-align: center;
  border-radius: 0 4px 4px 0;
  display: flex;

  input {
    border: none;
    width: 42px;
    text-align: center;

    &:focus-visible {
      border: none;
    }
  }
}

/deep/ .saler .el-input__inner {
  border-radius: 4px 0 0 4px;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}

.order-time {
  height: 40px;
  display: flex;
  align-items: center;
}

.print {
  &-list {
    display: flex;

    // flex-wrap: wrap;
    // justify-content: space-between;
    .item {
      width: 50%;
      // margin-right: 20px;
      display: flex;
      font-size: 14px;
      line-height: 30px;

      .left {
        width: 100px;
        font-weight: bold;
      }

      .print-right {
        flex: 1;
      }
    }
  }
}

.title {
  margin-top: 20px;
  font-weight: bold;
  line-height: 40px;
  font-size: 16px;
}

.my-table {
  line-height: 42px;

  .table-header {
    background: #eee;
  }

  .flex {
    display: flex;

    div {
      text-align: center;
      font-weight: bold;
    }
  }
}

.heji {
  display: flex;
  line-height: 40px;
  font-size: 14px;
  justify-content: flex-end;

  .item {
    margin-right: 20px;
    flex-direction: end;

    .left {
      font-weight: bold;
    }
  }
}

/deep/ .el-table__row .cell {
  padding: 0 12px !important;
}

.img {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}

.no-border {
  border-bottom: none;
}

/deep/ .btn {
  display: block;
  width: 100%;
  margin-top: 20px;
}

.success-list {
  div {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    line-height: 45px;

    span:nth-child(1) {
      color: #909399;
    }
  }
}

.icon {
  font-size: 60px;
  text-align: center;
  color: #2791ff;
}

/deep/ .el-button--primary {
  height: 40px;
}

.top-wrapper {
  margin-top: 10px;
  width: 77%;
  box-sizing: border-box;
  display: flex;

  .number {
    padding: 20px;
    width: 55%;
    background: white;

    &-content {
      display: flex;

      .number-avatar {
        min-width: 88px;
        height: 88px;
        border-radius: 50%;
        // background: #ccc;
        // overflow: hidden;
        position: relative;

        .img {
          width: 88px;
          height: 88px;
        }

        .label {
          position: absolute;
          bottom: -10px;
          width: 46px;
          height: 19px;
          background: pink;
          left: 21px;
        }
      }

      .number-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: 30px;
        font-size: 16px;

        .number-item {
          width: 40%;
          display: flex;
          // justify-content: space-between;
          margin: 0 20px;

          span:nth-child(1) {
            color: #909399;
            display: block;
            width: 120px;
          }
        }
      }
    }
  }

  .form {
    padding: 20px;
    width: 50%;
    background: white;
    margin-left: 10px;
  }
}

.list {
  .item {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    padding-left: 20px;

    div:nth-child(1) {
      font-size: 14px;
      color: #909399;
    }

    div:nth-child(2) {
      color: rgb(90, 90, 90);
      font-size: 14px;
    }
  }
}

.header {
  position: relative;
  padding-left: 10px;
  margin-bottom: 20px;
  display: flex;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    background: #5494f5;
    border-radius: 2px;
    top: 3px;
    left: 0px;
  }
}

.wrapper {
  display: flex;
  margin-top: 10px;

  .left {
    width: 77%;

    .info {
      margin-top: 10px;
      padding: 20px;
      background: white;
      padding-top: 0;
    }

    .top {
      background: white;
      padding: 0 20px 20px 20px;
      display: flex;
    }

    .btns {
      display: flex;
      flex: 1;
    }
  }

  .right {
    flex: 1;
    margin-left: 10px;
    background: white;
    padding: 20px;
    position: absolute;
    right: 0;
    width: 20%;
    top: 58px;
    bottom: 0;
  }
}

.no-bottom {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.member {
  display: flex;
  align-items: center;

  .box {
    display: flex;
    margin-left: 20px;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #ccc;
      overflow: hidden;
    }

    .username {
      font-size: 14px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .level {
      font-size: 14px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border: 1px solid #f6f7fb;
  border-top: none;

  // border-radius: 0px 0px 6px 6px;
  &-left {
  }

  &-right {
    display: flex;

    &-item {
      font-size: 14px;
      padding: 0 10px;

      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
</style>
