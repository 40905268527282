<template>
  <div>
    <el-dialog
      title="货品改价"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
      <div>
        <div class="good-info">
          <div class="good-info-img">
            <img :src="goodData.goods_pic" v-if="goodData.goods_pic" />
            <img src="../../../../assets/images/no_img.png" v-else />
          </div>
          <div class="list">
            <div class="list-item" v-for="item in goodInfo" :key="item.prop">
              <div class="list-item-left">
                {{item.txt}}
              </div>
              <div class="list-item-right">
                {{goodData[item.prop]? goodData[item.prop]: '--'}}
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="change">
          <div class="price">
            <el-form :model="goodData" label-width="100px">
              <el-form-item label="标签价">
                <span>￥{{goodData.sale_price}}</span>
              </el-form-item>
              <el-form-item>
                <el-radio-group v-model="radio" @change="handleChange" v-if="goodData['sales_type'] === 10">
                  <el-radio :label="10">手动折扣</el-radio>
                  <el-radio :label="20">工费优惠</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="折扣率(折)" v-if="radio === 10">
                <el-input v-model="goodData.discount" style="width: 30%; text-align: center;" @input="changeDiscount" :disabled="goodData.discount == '--'"></el-input>
                <span style="margin-left: 5px; color: rgb(231, 84, 30);" v-if="goodData.discount !== '--'">低于{{type === 2? '货品折扣': type === 3? '会员折扣': ''}}{{lowestDiscount}}折需要授权</span>
              </el-form-item>
              <el-form-item label="销售价" v-if="radio === 10">
                <el-input v-model="goodData.actual_sale_price" style="width: 80%;" @input="changeAct"></el-input>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="!authSucc && !authFail">未授权</span>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="authSucc">已授权</span>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="authFail">授权不通过</span>
              </el-form-item>
              <el-form-item label="销售工费" v-if="radio === 20">
                <el-input v-model="goodData['184']" style="width: 30%; text-align: center;" @input="handleGongfei"></el-input>
                <span style="color: rgb(231, 84, 30);"> 
                  {{!Number(currRow.originG)?'销售工费大于0':'销售工费在0-'+currRow.originG+'范围内'}}
                </span>
              </el-form-item>
              <el-form-item label="折扣率(折)" v-if="radio === 20">
                <el-input v-model="goodData.discount" style="width: 30%; text-align: center;" @input="changeDiscount" disabled></el-input>
                <span style="margin-left: 5px; color: rgb(231, 84, 30);">低于{{type === 2? '货品折扣': type === 3? '会员折扣': ''}}{{lowestDiscount}}折需要授权</span>
              </el-form-item>
              <el-form-item label="销售价" v-if="radio === 20">
                <el-input v-model="goodData.actual_sale_price" style="width: 80%;" disabled></el-input>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="!authSucc && !authFail">未授权</span>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="authSucc">已授权</span>
                <span style="color: #2791FF; font-size: 12px; margin-left: 10px;" v-if="authFail">授权不通过</span>
              </el-form-item>
              <!-- <el-form-item label="货品金额改价">
                <span style="color: #E7541E; font-size: 26px;">￥-{{goodData.changePrice || '0.00'}}</span>
              </el-form-item> -->
            </el-form>
          </div>
          <div class="code" v-if="needAuth">
            <div class="code-title">扫码授权</div>
            <div id="code" v-loading="creaAuthoLoading" style="position: relative; width: 148px; height: 148px;">
              <div  class="success" v-if="authSucc">
                <i class="el-icon-success"></i>
              </div>
               <div  class="success" v-if="authFail">
                <i class="el-icon-error"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import numeral from 'numeral';
import QRCode from 'qrcodejs2';
import { getGoodsDiscountReq, creaAuthReq, getAuthorizeReq } from '@/api/order/offlineOrder/add';

export default {
  data() {
    return {
      discount_type: 10,
      tipError: false,
      origin: {},
      radio: 10,
      type: null,
      qrcode: null,
      creaAuthoLoading: false,
      authorize_record_id: '', // 改价单ID
      authFail: false, // 授权失败
      authSucc: false, // 授权成功
      timer: null, // 节流标识
      needAuth: false, // 当前改价是否需要授权
      lowestDiscount: 0, // 最低折扣价
      goodData: {
        discount: '',
        actual_sale_price: '',
      },
      goodInfo: [
        {
          txt: '品名',
          prop: 'goods_name',
        },
        {
          txt: '主石类型',
          prop: '120',
        },
        {
          txt: '品类',
          prop: 'goods_type_name',
        },
        {
          txt: '成色',
          prop: '100',
        },
        {
          txt: '主石颜色',
          prop: '123',
        },
        {
          txt: '货号',
          prop: 'goods_number',
        },
        {
          txt: '款式',
          prop: '101',
        },
        {
          txt: '主石净度',
          prop: '124',
        },
        {
          txt: '款号',
          prop: 'goods_style_number',
        },
        {
          txt: '尺寸',
          prop: '109',
        },
        {
          txt: '主石重量(ct)',
          prop: '130',
        },
        {
          txt: '仓库',
          prop: 'warehouse_name',
        },
        {
          txt: '金重',
          prop: '104',
        },
        {
          txt: '品牌',
          prop: '103',
        },
        {
          txt: '证书类型',
          prop: '111',
        },
        {
          txt: '产地',
          prop: '105',
        },
        {
          txt: '证书号',
          prop: '112',
        },
      ],
    };
  },
  props: {
    originRadio: Number,
    main_store_user_id: Number,
    client_user_id: Number,
    dialogVisible: {
      type: Boolean,
    },
    currRow: {
      type: Object,
      default() {
        return {
          discount: '',
          actual_sale_price: '',
        };
      },
    },
  },
  watch: {
    // goodData: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal, oldVal);
    //     // 当前改变的是折扣率还是销售价
    //     if (newVal.discount !== oldVal.discount) {
    //       // 折扣率改变，计算新的销售价
    //       // eslint-disable-next-line no-mixed-operators
    //       this.goodData.actual_sale_price = numeral(Number(this.goodData.sale_price) * Number(this.goodData.discount) / 10).format('0.00');
    //       return;
    //     }
    //     if (newVal.actual_sale_price !== oldVal.actual_sale_price) {
    //       // 销售价改变，计算新的折扣率
    //       // eslint-disable-next-line no-mixed-operators
    //       this.goodData.discount = numeral(Number(this.actual_sale_price) * 10 / Number(this.goodData.sale_price)).format('0.00');
    //     }
    //     // 如果折扣价超过最低折扣价，需要授权
    //     if (this.goodData.discount < this.lowestDiscount) {
    //       this.$message.warning('超过系统设置最低折扣，请进行授权');
    //       this.needAuth = true;
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    console.log(this.currRow);
    this.goodData = {
      ...this.currRow,
      changePrice: numeral(Number(this.currRow.sale_price) - Number(this.currRow.actual_sale_price)).format('0.00'),
    };
    if (this.goodData.discount_type) {
      this.discount_type = this.goodData.discount_type;
      this.radio = this.discount_type;
    } else {
      this.discount_type = 10;
    }

    // 如果标签价是0，折扣率为--且不可填
    if (!Number(this.goodData.sale_price)) {
      this.goodData.discount = '--';
    }
    // 需要记录一些原始值
    // 如果销售方式是按克
    if (this.goodData.sales_type === 10) {
      const goodData = { ...this.goodData };
      this.origin = {
        discount: goodData.discount, // 折扣
        actual_sale_price: goodData.actual_sale_price, // 销售价
        sale_price: goodData.sale_price, // 标签价
        184: goodData.originG, // 销售工费
      };
    }
    this.getGoodsDiscount();
  },
  methods: {
    handleGongfei(e) {
      if (isNaN(Number(e))) {
        this.$message.error('请输入数字');
        this.tipError = true;
      } else {
        this.tipError = false;
      }
      if (Number(e) < 0) {
        this.$message.error('修改后的销售工费不能小于0');
        this.tipError = true;
      }
      if (Number(e) > Number(this.currRow.originG)) {
        this.$message.error('修改后的销售工费须小于工费金额');
        this.tipError = true;
      } else {
        this.tipError = false;
      }
      // 计算销售价和折扣率
      if (this.goodData["185"] === "按克") {
        this.goodData.actual_sale_price = (Number(e) + Number(this.goodData.gold_price)) * Number(this.goodData["104"]);
      }
      if (this.goodData["185"] === "按件") {
        this.goodData.actual_sale_price = Number(this.goodData.gold_price) * Number(this.goodData["104"]) + Number(e);
      }
      this.goodData.actual_sale_price = numeral(this.goodData.actual_sale_price).format("0.00");
      this.goodData.discount = Number(this.goodData.actual_sale_price) / Number(this.goodData.sale_price) * 10;
      this.goodData.discount = numeral(this.goodData.discount).format("0.00");
      this.handleAuth();
    },
    handleChange(e) {
      if (this.timer) {
        clearInterval(this.timer);
        this.authSucc = false;
        this.authFail = false;
        this.needAuth = false;
      }
      // 回填一进来的值
      const goodData = { ...this.goodData };
      if (this.discount_type === 10) {
        if (e === 10) {
          goodData.discount = this.origin.discount;
          goodData.actual_sale_price = this.origin.actual_sale_price;
        }
        if (e === 20) {
          goodData.discount = 10;
          goodData['184'] = this.currRow.originG;
          goodData.actual_sale_price = this.origin.sale_price;
        }
      } else {
        if (e === 10) {
          goodData.discount = 10;
          goodData.actual_sale_price = this.origin.sale_price;
        } else {
          goodData.discount = this.origin.discount;
          goodData['184'] = this.currRow.originG;
          goodData.actual_sale_price = this.origin.actual_sale_price;
        }
      }
      this.goodData = goodData;
    },
    // 轮询
    getAuthorize() {
      getAuthorizeReq(this.authorize_record_id)
        .then((res) => {
          if (res.code === 1) {
            const { data } = res;
            // 未过期
            if (data.validity_state) {
              // 未授权
              if (data.status === 10) {};
              // 授权通过
              if (data.status === 20) {
                clearInterval(this.timer);
                this.$message.success('授权通过');
                this.authSucc = true;
                this.authFail = false;
              }
              // 授权不通过
              if (data.status === 30) {
                clearInterval(this.timer);
                this.$message.error('授权不通过');
                this.authSucc = false;
                this.authFail = true;
              }
            } else {
              this.creaAuth();
            }
          }
        });
    },
    createQrcode() {
      if (!this.qrcode) {
        this.qrcode = new QRCode(document.getElementById('code'), {
          text: this.authorize_record_id,
          width: 148,
          height: 148,
          colorDark : '#000000',
          colorLight : '#ffffff',
        });
      } else {
        this.qrcode.clear();
        this.qrcode.makeCode(this.authorize_record_id);
      }
    },

    creaAuth() {
      this.creaAuthoLoading = true;
      this.authFail = false;
      this.authSucc = false;
      const goodData = { ...this.goodData };
      goodData.discount_price = goodData.actual_sale_price;
      creaAuthReq({
        goods_id: goodData.goods_id,
        goods_type_id: goodData.goods_type_id,
        discount: goodData.discount,
        discount_price: goodData.discount_price,
      })
        .then((res) => {
          if (res.code === 1) {
            this.creaAuthoLoading = false;
            this.authorize_record_id = res.data.code;
            // 生成二维码
            this.createQrcode();
            // 轮询
            if (this.timer) {
              clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
              this.getAuthorize();
            }, 1000);
          }
        })
        .catch(() => {
          this.creaAuthoLoading = false;
        });
    },
    // 点击确定
    handleTrue() {
      // 如果需要授权且没完成授权
      if (this.needAuth && !this.authSucc) {
        this.$message.warning('请完成授权');
        return;
      }
      if (this.tipError) {
        this.$message.warning('请填写正确的销售工费');
        return;
      }
      if (this.radio === 10) {
        this.goodData['184'] = this.currRow.originG;
      }
      this.$emit('onGoodData', { ...this.goodData, discount_type: this.radio });
      this.handleClose();
    },
    // 节流
    handleAuth() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        if ((this.goodData.discount < this.lowestDiscount) && (this.goodData.discount !== '--')) {
          this.$message.warning('超过系统设置最低折扣，请进行授权');
          this.needAuth = true;
          this.creaAuth();
        } else {
          this.needAuth = false;
          this.qrcode = null;
        }
      }, 500);
    },
    // 修改折扣率
    changeDiscount() {
      const goodData = { ...this.goodData };
      // eslint-disable-next-line no-mixed-operators
      goodData.actual_sale_price = numeral(Number(goodData.sale_price) * Number(goodData.discount) / 10).format('0.00');
      goodData.actual_sale_price_2 = numeral(Number(goodData.sale_price) * Number(goodData.discount) / 10).format('0.00');
      goodData.changePrice = numeral(Number(goodData.sale_price) - Number(goodData.actual_sale_price)).format('0.00');
      this.goodData = goodData;
      this.handleAuth();
    },
    changeAct(e) {
      const goodData = { ...this.goodData };
      goodData.actual_sale_price_2 = goodData.actual_sale_price;
      // eslint-disable-next-line no-mixed-operators
      if (goodData.discount !== '--') {
        goodData.discount = numeral(Number(goodData.actual_sale_price) * 10 / Number(goodData.sale_price)).format('0.00');
      }
      goodData.changePrice = numeral(Number(goodData.sale_price) - Number(goodData.actual_sale_price)).format('0.00');
      if (goodData.discount === 'NaN') {
        goodData.discount = '--';
      }
      goodData.actual_sale_price = goodData.actual_sale_price.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
      this.goodData = goodData;
      this.handleAuth();
    },

    // 获取货品最低折扣
    getGoodsDiscount() {
      getGoodsDiscountReq({
        client_user_id: this.client_user_id,
        goods_id: this.goodData.goods_id,
        main_store_user_id: this.main_store_user_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.lowestDiscount = res.data.discount;
            this.type = res.data.discountType;
          }
        });
    },

    handleClose() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.$emit('onHideChange');
    },
  },
};
</script>

<style lang="less" scoped>
.success {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 148px;
  background: rgba(250,250, 250, .8);
  .el-icon-success {
    font-size: 50px;
    color: #67C23A;
    z-index: 99;
  }
  .el-icon-error {
    font-size: 50px;
    color: #F56C6C;
    z-index: 99;
  }
}
.good-info {
  display: flex;
  &-img {
    width: 240px;
    height: 240px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    &-item {
      width: 33%;
      display: flex;
      &-left {
        width: 40%;
        color: #909399;
      }
    }
  }
}
.change {
  display: flex;
  .price {
    width: 50%;
  }
  .code {
    flex: 1;
    margin-left: 50px;
    &-title {
      margin: 10px 0;
    }
    &-img {
      width: 148px;
      height: 148px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
