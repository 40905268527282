<template>
  <el-dialog
    title="储值金抵扣"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="960"
    :before-close="handleClose">
    <div class="top">
      <span>本单可抵扣<span class="blue">{{storageMoneyInfo.max_storage_money}}元</span></span>
      <span style="margin-left: 25px;">已抵扣<span class="blue">{{sum}}元</span></span>
    </div>
    <div class="box">
      <div class="item" v-for="item in goods" :key="item.goods_id">
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">品名</span>
          <span style="color: black;">{{item.goods_name}}</span>
        </div>
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">货号</span>
          <span style="color: black;">{{item.goods_number}}</span>
        </div>
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">品类</span>
          <span style="color: black;">{{item.goods_type_name}}</span>
        </div>
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">销售价</span>
          <span style="color: #E7541E;">￥{{item.actual_sale_price}}</span>
        </div>
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">抵扣比例</span>
          <span style="color: black;">{{item.storage_money_discount}}%</span>
        </div>
        <div>
          <span style="color: #909399; margin-right: 20px; width: 100px; display: inline-block; text-align: right;">储值金抵扣</span>
          <el-input v-model="item.storage_money" type="number" style="width: 200px;" @input="handleInput($event, item)" v-if="item.storage_money_discount"></el-input>
          <span v-else>--</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleTrue">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import numeral from 'numeral';

export default {
  data() {
    return {
      errorFlg: false,
      timer: null, // 计时器
      saveNum: 0,
      goods: [],
      sum: 0,
    };
  },
  created() {
    this.goods = JSON.parse(JSON.stringify(this.newArrival));
    // this.goods = this.goods.map((item) => {
    //   const storage_money = numeral(item.storage_money_discount * item.actual_sale_price / 100).format('0.00');
    //   return {
    //     ...item,
    //     storage_money,
    //   };
    // });
    this.handleSum();
  },
  props: {
    dialogVisible: Boolean,
    newArrival: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 0,
    },
    storageMoneyInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleTrue() {
      if (this.errorFlg) {
        this.$message.warning('请输入正确的储值金抵扣');
        return;
      }
      this.$emit('onChange', {
        use_money: this.sum,
        goods: this.goods,
      });
    },
    handleInput(e, item) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        if (Number(e) < 0) {
          this.$message.warning('储值金抵扣金额不得小于0');
          this.errorFlg = true;
          return
        } else {
          this.errorFlg = false;
        }
        if (Number(e) > item.max_discount_money) {
          this.$message.warning('储值金抵扣金额超出抵扣比例');
          this.errorFlg = true;
          return
        } else {
          this.errorFlg = false;
        }
        if (e.split('.').length >= 2) {
          this.$message.warning('储值金抵扣金额只能为整数');
          this.errorFlg = true;
          return
        } else {
          this.errorFlg = false;
        }
        this.handleSum();
        if (Number(this.sum) > Number(this.storageMoneyInfo.max_storage_money)) {
          this.$message.warning('储值金抵扣金额超出本单可抵扣金额');
          this.errorFlg = true;
          return
        } else {
          this.errorFlg = false;
        }
      }, 500);
    },
    handleSum() {
      let sum = 0;
      console.log(this.goods);
      this.goods.forEach((item) => {
        if (!item.pcs) {
          item.pcs = item.num;
        }
        sum += (Number(item.storage_money) * Number(item.pcs));
      });
      this.sum = numeral(sum).format('0.00');
    },
    handleClose() {
      this.$emit('onBack');
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  text-align: center;
  .blue {
    color: #2791FF;
    margin-left: 10px;
  }
}
.box {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 420px;
    height: 228px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      line-height: 30px;
    }
  }
}
</style>
